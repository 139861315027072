//@ts-nocheck
import React from "react";
// Customizable Area Start
import MerchantInvoicesController, {
  Props,
} from "./MerchantInvoicesController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withLoaderPayment } from "../../../components/src/withLoaderPayment.Web";
import withRouter from "react-router/withRouter";
import { Button, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody ,InputBase } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { appIcon ,closesvg } from "./assets";
import './index.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Dialog from '@material-ui/core/Dialog';
import moment from"moment";

class MerchantInvoicesDwonload extends React.Component {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleInputChange = (e) => {
    const { onInputChange } = this.props;
    const newValue = e.target.value;
    onInputChange(newValue);
  }

  render() {
    const { classes } = this.props;
    const { open,
      handleClose,
      invoicePdfUrl,
      createTransaction,
      rowData,
      inputValue,
      payWithUpiMethod,
      nextStep,
      prevStep,
      step  } = this.props;
    const {
      no_of_greyscale_copies,
      no_of_coloured_copies,
      status,
      payable_amount,
      invoice_no,
      start_date,
      end_date,
      cgst,
      sgst,
      igst,
      amount_with_gst
    } = rowData;
    return (
      <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="invoiceDialog"
        >
        {step === 1 && (
          <div className="invoiceSection" >
            <div className="invoiceBg">
              <div className="closeIcon" >
                <img src={closesvg} alt="" onClick={handleClose} />
              </div>
              <Grid className="invoiceTopArea">
                <Box className="invoiceLogoArea">
                  <img
                    src={appIcon}
                    alt=""
                    className=""
                    onClick={this._handleAllShopsModal}
                  />
                  <Typography variant="h4">PRINTSEC PRIVATE LIMITED</Typography>
                </Box>
                <Box className="invoicedatedArea">
                  <div className="invoiceDetails">
                    <Typography variant="subtitle1">Billing Date</Typography>
                    <Typography variant="h6" >{moment(end_date).format('DD-MM-YYYY')}</Typography>
                  </div>
                  <div className="invoiceDateDetails">
                    <div>
                      <Typography variant="subtitle1">Billing Start Date</Typography>
                      <Typography variant="h6">{moment(start_date).format('DD-MM-YYYY')}</Typography>
                    </div>
                    <div>
                      <Typography variant="subtitle1">Billing End Date</Typography>
                      <Typography variant="h6">{moment(end_date).format('DD-MM-YYYY')}</Typography>
                    </div>
                  </div>
                  <div className="invoiceDetails">
                    <div>
                      <Typography variant="subtitle1">Invoice Id</Typography>
                      <Typography variant="h6">{invoice_no}</Typography>
                    </div>
                    <div>
                      <Typography variant="subtitle1">Status</Typography>
                      <Typography variant="h6"><span className={classes[status]}>{status}</span></Typography>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid className="invoiceHeaderBottomArea">
              </Grid>
            </div>
            <div className="invoiceInTable">
              <TableContainer>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Total No of GreyScale Copies</TableCell>
                      <TableCell align="center">Total No of Coloured Copies</TableCell>
                      <TableCell align="center">Bill Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{no_of_greyscale_copies}</TableCell>
                      <TableCell align="center">{no_of_coloured_copies}</TableCell>
                      <TableCell align="center">{payable_amount}</TableCell>
                    </TableRow>
                   {cgst !==null && <TableRow>
                      <TableCell align="right" colSpan={2}>CGST</TableCell>
                      <TableCell align="center">{cgst}</TableCell>
                    </TableRow>}

                   {sgst !==null &&<TableRow>
                      <TableCell align="right" colSpan={2}>SGST</TableCell>
                      <TableCell align="center">{sgst}</TableCell>
                    </TableRow>}

                    {igst !==null &&<TableRow>
                      <TableCell align="right" colSpan={2}>IGST</TableCell>
                      <TableCell align="center">{igst}</TableCell>
                    </TableRow>}

                    {<TableRow>
                      <TableCell align="right" colSpan={2}>Total Amount</TableCell>
                      <TableCell align="center">{amount_with_gst}</TableCell>
                    </TableRow>}
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell style={{ padding: '20px 0' }} colSpan={2} align="right" >
                        { status !== "paid" &&
                        <Button
                          className={classes.findPrintersBtn + ' print-hidden'}
                            onClick={nextStep}
                          >
                            Pay Now
                          </Button>
                        }
                        <Button
                          className={classes.downloadBtn + ' print-hidden'}
                          onClick={() => {window.open(invoicePdfUrl, '_blank')}}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="invoiceSection" >
              <Grid container className={classes.rightContent}>
                <Grid item xs={12} className={classes.headingCont}>
                  <button onClick={prevStep} className="invoiceBack">
                    <img src={require('../assets/backIcon.png')} alt="Back"/>
                  </button>
                  <Typography className={classes.selectFilesText}>Select Payment Method</Typography>
                </Grid>

                <Grid container className={classes.paymentCont}>
                  <Grid item xs={6} sm={6} className={classes.upiCont}>
                    <div
                      className={classes.upiContent}
                      onClick={nextStep}

                    >
                      <img src={require('../assets/group-3.svg')} alt="" className={classes.upiImg} />
                      <div>
                        <Typography className={classes.payByText}>Pay By</Typography>
                        <Typography className={classes.payMethodText}>UPI</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.upiCont}>
                    <div
                      className={classes.upiContent}
                      onClick={() => { createTransaction() }}
                      style={{
                        marginLeft: '20px'
                      }}
                    >
                      <img src={require('../assets/card.svg')} alt="" className={classes.upiImg} />
                      <div>
                        <Typography className={classes.payByText}>Pay By</Typography>
                        <Typography className={classes.payMethodText}>Other Methods</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}

          {step === 3 && (
            <div
              aria-labelledby="alert-dialog-title"
              className="raise-concern-dialog popup-custom popupCustom"
              aria-describedby="alert-dialog-description"
            >
              <Typography id="alert-dialog-title" className="headingContSec">
                <button onClick={prevStep} className="invoiceBack">
                  <img src={require('../assets/backIcon.png')} alt="Back"/>
                </button>
                <span className={classes.upiPayTxt}>Pay with UPI</span>
              </Typography>
              <div className="upiInputRaise">
                <label>Your UPI</label>
                <InputBase
                  name="upi"
                  value={inputValue}
                  onChange={this.handleInputChange}
                  placeholder="Type your UPI here"
                  style={{ border: '1px solid lightgray' }}
                  className="textFieldInput-raise-concern"
                />
                <Button
                  style={{ backgroundColor: "#32795f" }}
                  className={classes.closeModalBtn}
                  onClick={() => { payWithUpiMethod() }}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}

        </Dialog>
      </div>
    </>
    );
  }
}
// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    findPrintersBtn: {
      color: "white",
      margin: "5px 5px",
      fontSize: "14px",
      maxWidth: "300px",
      textTransform: "capitalize",
      backgroundColor: '#ffa726',
      '&:hover': {
        backgroundColor: '#ffa726',
      },
    },
    downloadBtn: {
      color: "white",
      margin: "5px 5px",
      fontSize: "14px",
      maxWidth: "300px",
      textTransform: "capitalize",
      backgroundColor: '#32795f',
      '&:hover': {
        backgroundColor: "#32795f",
      },
    },
    headingCont: {
      width: "100%",
      margin: "0 auto",
    },
    labelText: {
      fontSize: 22,
      margin: "20px 0 10px 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    labelContent: {
      fontSize: "12px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    backIcon: {
      width: 40,
      height: 40,
      marginLeft: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    billingtableCellhead: {
      fontSize: "18px",
      fontWeight: "700",
    },
    billingtableCell: {
      fontSize: "16px",
      fontWeight: "400",
    },
    paid: {
      backgroundColor: "rgb(25,135,84)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    unpaid: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    pending: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    columnHeaderWrapper: {
      margin: '0 auto',
      width: '92%',
      height: '80vh',
    },
    failed: {
      backgroundColor: "rgb(255 ,0,0)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      color: "white",
      minWidth: "70px",
      textAlign: "center",
    },
    headingCont: {
      width: '90%',
      display: "flex",
      margin: "20px",
      alignItems: "center"
    },
    selectFilesText: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#1e1f20'
    },
    paymentCont: {
      width: '100%',
      display: "flex",
      margin: "20px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    upiCont: {
      height: 220,
      marginTop: 20,
      cursor: "pointer"
    },
    upiContent: {
      width: '90%',
      display: "flex",
      height: '100%',
      borderRadius: 12,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      backgroundColor: "#f1f1f1",
    },
    upiImg: {
      height: 45,
      width: 45,
      margin: '15px auto',
      objectFit: 'contain'
    },
    payByText: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'center',
      color: '#8f92a1',
      marginTop: 8
    },
    payMethodText: {
      fontSize: 17,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'center',
      color: '#1e1f20',
      marginTop: 8
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "50%",
      marginTop: "20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    upiPayTxt: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#1e1f20'
    },
  });
// Customizable Area End
const MerchantInvoicesWithConfirmBox = withConfirmBox(MerchantInvoicesDwonload);
const MerchantInvoicesWithLoader = withLoader(MerchantInvoicesWithConfirmBox);
const MerchantInvoicesWithToast = withToast(MerchantInvoicesWithLoader);
const MerchantInvoicesWithPaymentLoader = withLoaderPayment(MerchantInvoicesWithToast)
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantInvoicesWithPaymentLoader)
);
