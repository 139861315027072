//@ts-nocheck
import React from "react";
// Customizable Area Start
import MerchantInvoicesController, {
  Props,
} from "./MerchantInvoicesController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import { withLoaderPayment } from "../../../components/src/withLoaderPayment.Web";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { closesvg } from "./assets";
import './index.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Dialog from '@material-ui/core/Dialog';
import FileViewer from "react-file-viewer";

class MerchantInvoicesViewWeb extends MerchantInvoicesController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const { view, handleCloseView ,invoicePdfUrl} = this.props;
    const file = invoicePdfUrl;
    const type = "pdf";

    return (
      <>
        <div>
          <Dialog
            open={view}
            onClose={handleCloseView}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="invoiceDialog"
          >

            <div>
              <div className="closeIcon" >
                <img
                  src={closesvg} alt=""
                  onClick={handleCloseView}
                />
              </div>
            </div>
            <div className="viewInvoices">
              <FileViewer
                fileType={type}
                filePath={file}
              />
            </div>
          </Dialog>
        </div>
      </>
    );
  }
}
// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    findPrintersBtn: {
      color: "white",
      margin: "5px 5px",
      fontSize: "14px",
      maxWidth: "300px",
      textTransform: "capitalize",
      backgroundColor: "#32795f",
      '&:hover': {
        backgroundColor: "#32795f",
      },
    },
    headingCont: {
      width: "100%",
      margin: "0 auto",
    },
    labelText: {
      fontSize: 22,
      margin: "20px 0 10px 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    labelContent: {
      fontSize: "12px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    backIcon: {
      width: 40,
      height: 40,
      marginLeft: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    billingtableCellhead: {
      fontSize: "18px",
      fontWeight: "700",
    },
    billingtableCell: {
      fontSize: "16px",
      fontWeight: "400",
    },
    Paid: {
      backgroundColor: "rgb(25,135,84)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
    },
    Pending: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
    },
    columnHeaderWrapper: {
      margin: '0 auto',
      width: '92%',
      height: '80vh',
    },
    Failed: {
      backgroundColor: "rgb(255 ,0,0)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      color: "white",
      minWidth: "70px",
      textAlign: "center",
    },
  });
// Customizable Area End
const MerchantInvoicesViewWithConfirmBox = withConfirmBox(MerchantInvoicesViewWeb);
const MerchantInvoicesViewWithLoader = withLoader(MerchantInvoicesViewWithConfirmBox);
const MerchantInvoicesViewWithToast = withToast(MerchantInvoicesViewWithLoader);
const MerchantInvoicesViewWithPaymentLoader = withLoaderPayment(MerchantInvoicesViewWithToast)
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantInvoicesViewWithPaymentLoader)
);
