//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
// Customizable Area Start

// Customizable Area End

export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  loading: boolean;
  couponsData : any;
  showCoupon : boolean;
  selectedCoupanData : object
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CouponsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCouponsCallId: String = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.AlertMessage)];

    this.state = {
      loading: false,
      couponsData : [],
      showCoupon : false, 
      selectedCoupanData : {}
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetAllCoupons = (responseJsonCouponData:any)=>{
    this.setState({ loading: false });
    this.props.hideLoader();
    if (responseJsonCouponData && responseJsonCouponData.data) {
      this.setState({
        couponsData: responseJsonCouponData.data,
      });
    }
    else {
      this.props.showNotification(responseJsonCouponData.message, 'error');
    }
  }

  handleGetApplyDiscount= (responseJsonCouponData : any)=>{ 
    this.setState({ loading: false });
    this.props.hideLoader();
    if (responseJsonCouponData && !responseJsonCouponData.errors && !responseJsonCouponData.error) {
      this.props.setCouponData({...this.state.selectedCoupanData, ...responseJsonCouponData})
      this.props.showNotification(responseJsonCouponData.message, "success");
    }
    else {
      this.props.showNotification(responseJsonCouponData.error ? responseJsonCouponData.error : responseJsonCouponData?.errors?.[0]?.message || 'Unknown error',
      'error');
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      let AlertBodyMessageCouponsPage = message.getData(
        getName(MessageEnum.AlertBodyMessageCouponsPage)
      );
      this.props.showNotification(AlertBodyMessageCouponsPage, "error");
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJsonCouponData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apigetCouponsCallId) {
        this.handleGetAllCoupons(responseJsonCouponData)
      }
      else if (apiRequestCallId === this.apiGetCouponDiscountCallId) {
       this.handleGetApplyDiscount(responseJsonCouponData);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentWillUnmount() {
    clearInterval(this.handleTimeInterval)
  }

  getCoupons() {
    const header = {
      "Content-Type": configJSON.contentType,
    //   "token": this.state.token
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetCouponsCallId = requestMessage.messageId;

    let getcouponsAPIEndPoint = configJSON.getcouponsAPIEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getcouponsAPIEndPoint)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getDiscountAmount(couponDataItem:any) {
    this.setState({selectedCoupanData:{couponCode:couponDataItem.attributes.coupon_code}, loading: true})
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.token,
    }
    const httpBody = {
      "account_id": Number(this.props.account_id),
      "order_id": Number(this.props.order_id),
      "coupon_code": couponDataItem.attributes.coupon_code,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCouponDiscountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCouponDiscontEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  // Customizable Area End
}