// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withToast } from "../../../components/src/withSnackBar.Web";
import CouponsController, {
  Props,
  configJSON
} from "./CouponsController.Web";
import { arrowRightIcon, offersBnc, arrowUp } from "./assets";
// Customizable Area Start
// Customizable Area End
class Coupons extends CouponsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          token: loginData.token,
        });
      }
    });
    await this.getCoupons();
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const coupanHandler = (item: any) => {
      return <Grid
        container
        alignItems={'center'}
        justifyContent={"space-between"}
        className={classes?.orderNowEpicCoupons}
      >
        <Grid item xs={9} key={item.id}>
          <Typography variant="h5" className={classes?.orderNowMainheadCoupons}>{item.attributes.coupon_code}</Typography>
          <Typography variant="h5" className={classes?.orderNowSubheadCoupons}>{item.attributes.coupon_description}</Typography>
        </Grid>
        <Grid item xs={3} className={classes?.orderNowSecCoupons}>
          <Button
            variant="outlined"
            data-test-id = "discountAmount"
            onClick={()=>this.getDiscountAmount(item)}
            className={classes?.orderNowBtnCoupons}
          >
            Select
          </Button>
        </Grid>
      </Grid>
    }
    const { classes } = this.props;
    return (
      <Grid container className={classes?.offerSidebarBgCoupons}>
        <Grid
          item
          sm={this.props.isPayment ? 12 : 8}
          xs={12}
          className={classes?.leftSidebarCoupons}
        >
          {this.props.isPayment ? 
          (
            <Grid>
              <Box className={classes?.accordionSec}>
                <Box className={classes?.applyBlk}>
                  <img src={offersBnc} alt="Offers" />
                  <Typography variant="h4">Apply Coupon</Typography>
                </Box>
                <Box >
                  <img
                    src={arrowUp}
                    alt="Arrow"
                    data-test-id="couponDataShow"
                    onClick={() => {
                      this.setState({ showCoupon: !this.state.showCoupon });
                    }}
                    className={
                      this.state.showCoupon
                        ? classes?.accordionIcon
                        : classes?.transformAccordionIcon
                    }
                  />
                </Box>
              </Box>
            </Grid>
          ) : (
            <>
              <Grid
                container
                alignItems={"center"}
                className={classes?.parentContainerCoupons}
              >
                <Grid item xs={3}>
                  <Box className={classes?.backIconCoupons}>
                    <img
                      src={arrowRightIcon}
                      data-test-id="navigationHandleCoupon"
                      className={classes?.editProfileArrowIconCoupons}
                      onClick={() =>
                        this.props.history?.push(
                          "/UserSettings/Settings/OfferAndDiscounts"
                        )
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes?.labelSettingCoupons}>
                    {configJSON.couponsTxt}
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                className={classes?.applyTextBlockCoupons}
              >
                <Grid item xs={10} className={classes?.validInputCoupons}>
                  <TextField
                    type="text"
                    variant="outlined"
                    className={classes?.textField1}
                    required
                    placeholder="Please enter a valid coupan code"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h5" className={classes?.applyText}>
                    Apply
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Box className={this.props.isPayment && this.state.showCoupon ? classes?.boxLists: ""}>
            {this.state.couponsData.map((item) =>
              this.props.isPayment
                ? this.state.showCoupon && coupanHandler(item) :
                coupanHandler(item)
            )}
          </Box>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    offerSidebarBgCoupons: {
      backgroundColor: "#f1f1f1",
      height: "100%",
    },
    leftSidebarCoupons: {
      margin: '0 auto',
    },
    sofiaFont: {
      fontFamily: APP_FONTS.SofiaProRegular
    },
    parentContainerCoupons: {
      padding: "16px",
      backgroundColor: '#fff',
      marginBottom: 20,
    },
    backIconCoupons: {
      height: '48px',
      width: '48px',
      backgroundColor: '#32795f',
      borderRadius: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 15,
      cursor: 'pointer',
      transform: 'rotate(180deg)',
    },
    editProfileArrowIconCoupons: {
      width: "21px",
      height: "21px",
      margin: '0 15px',
    },
    labelSettingCoupons: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "20px",
      fontWeight: 700,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      textTransform: 'uppercase',
      color: "#1e1f20",
      marginBottom: 0,
      textAlign: 'center'
    },
    orderNowEpicCoupons: {
      backgroundColor: '#fff',
      padding: '20px 30px',
      marginBottom: 20,
    },
    orderNowSecCoupons: {
      textAlign: 'right',
    },
    orderNowBtnCoupons: {
      borderColor: '#32795f',
      color: '#32795f',
      textTransform: 'capitalize',
      fontFamily: APP_FONTS.SofiaProRegular,
      fontWeight: 700,
      fontSize: 16,
      padding: '2px 16px',
    },
    orderNowMainheadCoupons: {
      color: '#32795f',
      textTransform: 'uppercase',
      marginBottom: 5,
      fontFamily: APP_FONTS.SofiaProRegular
    },
    orderNowSubheadCoupons: {
      color: '#8f92a1',
      fontFamily: APP_FONTS.SofiaProRegular
    },
    applyTextBlockCoupons: {
      margin: '20px 0',
      textAlign: 'center',
    },
    applyText: {
      cursor: 'pointer',
      color: '#32795f',
      fontWeight: 700,
      textTransform: 'capitalize',
      fontFamily: APP_FONTS.SofiaProRegular
    },
    validInputCoupons: {
      '& .MuiFormControl-root': {
        width: '100%',
        alignItems: 'center',
        '& .MuiInputBase-root': {
          width: '100%',
          '& input': {
            backgroundColor: '#fff',
            padding: '8px 15px !important',
            fontSize: '16px !important',
            borderRadius: 12,
            width: '84% !important',
            margin: '0 auto'
          },
          '& fieldset': {
            border: 'none',
          }
        }
      }
    },
    accordionSec: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 15,
      padding: 20,
      borderBottom: '1px solid #cccccc80',
      '& img': {
        height: 15,
      }
    },
    applyBlk: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
      '& img': {
        height: 32,
        width: 32,
        filter: 'brightness(0.5)',
      }
    },
    accordionIcon: {
      cursor: 'pointer',
      margin: '0 15px'
    },
    transformAccordionIcon: {
      cursor: 'pointer',
      margin: '0 15px',
      transform: 'rotate(180deg)',
    },
    boxLists: {
      height: 'calc(100vh - 160px) !important',
      overflow: 'auto',
    }
  });
// Customizable Area End

const CouponsBlockWithLoader = withLoader(Coupons)
const CouponsBlockWithToast = withToast(CouponsBlockWithLoader)
export { Coupons }
export default withRouter(withStyles(styles, { withTheme: true })(CouponsBlockWithToast));