// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
export const configJSON = require("./config");
import { arrowRightIcon, offersBnc, arrowDown, slideBnc, excelBnc, printerDocIcon, paytmBnc } from "./assets";
// Customizable Area Start
// Customizable Area End
class Payment extends React.Component {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Grid container className={classes.offerSidebarBgPayment}>
                <Grid item sm={8} xs={12} className={classes.leftSidebarPayment}>
                    <Grid container alignItems={'center'} className={classes.parentContainerPayment}>
                        <Grid item xs={3}>
                            <Box className={classes.backIconPayment
                            }>
                                <img
                                    src={arrowRightIcon}
                                    className={classes.editProfileArrowIconPayment}
                                    onClick={()=>this.props.history.push("/UserSettings/Settings/OfferAndDiscounts")}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.labelSettingPayment}>{configJSON.paymentTxt}</p>
                        </Grid>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} className={classes.applyTextBlock}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.assuredPrintTextPayment}>Assured Print Quality</Typography>
                        </Grid>
                    </Grid>
                    <Box className={classes.paymentContainer}>
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={"space-between"}
                        >
                            <Grid item xs={9} className={classes.offersBncTxtPayment}>
                                <img
                                    src={offersBnc}
                                    alt="Offers"
                                />
                                <Typography variant="h5" className={classes.paymentSubheadPayment}>Apply Coupon</Typography>
                            </Grid>
                            <Grid item xs={3} className={classes.paymentSecPayment}>
                                <img
                                    src={arrowDown}
                                    alt="Offers"
                                />
                                <Button variant="text" className={classes.paymentBtnPayment}>TryNow</Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={"space-between"}
                            className={classes.paymentContainerOpenPayment}
                        >
                            <Grid item xs={9}>
                                <Typography variant="h5" className={classes.paymentMainheadPayment}>Epic</Typography>
                                <Typography variant="h5" className={classes.paymentSubheadPayment}>Extra Upto 25% off on 2589 and above. max discount Rs.800</Typography>
                            </Grid>
                            <Grid item xs={3} className={classes.paymentSecPayment}>
                                <Button variant="text" className={classes.paymentBtnPayment}>Select</Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.paymentPrintContainer}>
                        <Box className={classes.paymentHeadingPayment}>
                            <Typography variant="h4">Order Details</Typography>
                        </Box>
                        <Box className={classes.paymentPrintSch}>
                            <Grid
                                container
                                alignItems={'center'}
                                justifyContent={"space-between"}
                            >
                                <Grid item xs={10} className={classes.printFeedback}>
                                    <img
                                        src={excelBnc}
                                        alt="Feedback"
                                    />
                                    <Typography variant="h4">Feedback_03</Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.printpreviewTxtPayment}>
                                    <img
                                        src={printerDocIcon}
                                        alt="Offers"
                                    />
                                    <Typography variant="h6" className={classes.printPayment}>Print Preview</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={2} pb={2}>
                            <Box className={classes.paymentOrderDetails}>
                                <Typography variant="h5">Order ID</Typography>
                                <Typography variant="h5">#123232</Typography>
                            </Box>
                            <Box className={classes.paymentOrderDetails}>
                                <Typography variant="h5">Amount</Typography>
                                <Typography variant="h5">#450</Typography>
                            </Box>
                            <Box className={classes.paymentOrderDetails}>
                                <Typography variant="h5">Coupon Savings</Typography>
                                <Typography variant="h5" className={classes.paymentOrderDetailsApplied}>Apply Coupon</Typography>
                            </Box>
                            <Box className={classes.paymentOrderDetails}>
                                <Typography variant="h5">Delivery Fee</Typography>
                                <Typography variant="h5">#16</Typography>
                            </Box>
                            <Box className={classes.paymentOrderDetails}>
                                <Typography variant="h5">Total Amount</Typography>
                                <Typography variant="h5">#466</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Grid
                            container
                            alignItems={'center'}
                            justifyContent={"space-between"}
                            className={classes.payOptionContainerPayment}
                        >
                            <Grid item xs={9} className={classes.payOptionSection}>
                                <img src={paytmBnc} alt="Paytm"/>
                                <Box>
                                    <Typography variant="h5" className={classes.payOptionHead}>Pay Using</Typography>
                                    <Typography variant="h4" className={classes.payOptionSubhead}>Paytm UPI</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3} className={classes.paymentSecPayment}>
                                <Button variant="text" className={classes.changeBtnPayment}>change</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Box  className={classes.paymentSlide}>
                                    <Box className={classes.slideIcon}>
                                        <img src={slideBnc} alt="Slide"/>
                                    </Box>
                                    <Box className={classes.slideTextPayment}>
                                        <Typography variant="h4">Slide to pay | 300</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        offerSidebarBgPayment: {
            backgroundColor: "#f1f1f1",
            height: "100%",
        },
        leftSidebarPayment: {
            margin: '0 auto',
        },
        parentContainerPayment: {
            padding: "16px",
            backgroundColor: '#fff',
            marginBottom: 20,
        },
        backIconPayment
        : {
            height: '48px',
            width: '48px',
            backgroundColor: '#32795f',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 15,
            cursor: 'pointer',
            transform: 'rotate(180deg)',
        },
        editProfileArrowIconPayment: {
            width: "21px",
            height: "21px",
            margin: '0 15px',
        },
        sofiaFont: {
            fontFamily: APP_FONTS.SofiaProRegular
        },
        labelSettingPayment: {
            fontFamily: APP_FONTS.SofiaProRegular,
            fontSize: "20px",
            fontWeight: 700,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.8,
            letterSpacing: "3px",
            textTransform: 'uppercase',
            color: "#1e1f20",
            marginBottom: 0,
            textAlign: 'center'
        },
        paymentContainer: {
            borderRadius: 15,
            width: '94%',
            backgroundColor: '#fff',
            margin: '20px auto',
            padding: 20,
        },
        paymentHeadingPayment: {
            textAlign: 'center',
            padding: 20,
            '& h4': {
                fontWeight: 700,
                fontFamily: APP_FONTS.SofiaProRegular,
            }
        },
        paymentSecPayment: {
            textAlign: 'right',
            '& img': {
                height: 14,
                width: 14,
            }
        },
        paymentBtnPayment: {
            color: '#32795f',
            textTransform: 'uppercase',
            fontFamily: APP_FONTS.SofiaProRegular,
            fontWeight: 700,
            fontSize: 16,
            padding: '2px',
        },
        paymentMainheadPayment: {
            color: '#32795f',
            fontWeight: 700,
            textTransform: 'uppercase',
            marginBottom: 5,
            fontFamily: APP_FONTS.SofiaProRegular
        },
        paymentSubheadPayment: {
            color: '#8f92a1',
            fontFamily: APP_FONTS.SofiaProRegular
        },
        assuredPrintTextPayment: {
            cursor: 'pointer',
            color: '#32795f',
            textTransform: 'capitalize',
            textAlign: 'center',
            fontFamily: APP_FONTS.SofiaProRegular
        },
        offersBncTxtPayment: {
            display: 'flex',
            alignItems: 'center',
            gap: 15,
            '& img': {
                height: 45,
                width: 45,
            }
        },
        paymentContainerOpenPayment: {
            padding: '20px 5px 5px',
        },
        paymentOrderDetails: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 15,
            padding: '15px 20px',
            '& h5': {
                fontFamily: APP_FONTS.SofiaProRegular,
            }
        },
        paymentOrderDetailsApplied: {
            color: '#32795f',
            fontWeight: 700,
        },
        paymentPrintContainer: {
            borderRadius: 20,
            width: '94%',
            backgroundColor: '#fff',
            margin: '20px auto',
        },
        paymentPrintSch: {
            backgroundColor: '#f1f1f1',
            padding: '15px 20px'
        },
        printFeedback: {
            display: 'flex',
            alignItems: 'center',
            gap: 15,
        },
        printPayment: {
            lineHeight: 1.2,
        },
        printpreviewTxtPayment: {
            textAlign: 'center',
            padding: '0 15px',
            marginBottom: 5,
            '& img': {
                height: 40,
                width: 40,
                backgroundColor: '#32795f',
                borderRadius: 12,
                padding: 8,                
                cursor: 'pointer'
            }
        },
        payOptionContainerPayment: {
            backgroundColor: '#fff',
            padding: 20,
            '& .MuiTypography-root': {
                fontFamily: APP_FONTS.SofiaProRegular,
            }
        },
        changeBtnPayment: {
            fontSize: 15,
            color: 'red',
            textTransform: 'capitalize',
        },
        payOptionSection: {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            '& img': {
                height: 40,
            }
        },
        paymentSlide: {
            backgroundColor: '#32795f',
            borderRadius: 50,
            padding: 5,
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            margin: '15px 0',
        },
        slideIcon: {
            cursor: 'pointer',
            height: 75,
            width: 75,
            backgroundColor: '#fff',
            borderRadius: '50%',
            '& img': {
                height: '100%',
                width: '100%',
            }
        },
        slideTextPayment: {
            textAlign: 'center',
            width: 'calc(100% - 75px)',
            '& .MuiTypography-root': {
                fontWeight: 700,
                textTransform: 'uppercase',
                fontFamily: APP_FONTS.SofiaProRegular,
            }
        }
    });
// Customizable Area End

const PaymentBlockWithLoader = withLoader(Payment)
const PaymentBlockWithToast = withToast(PaymentBlockWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(PaymentBlockWithToast));
