// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
export const configJSON = require("./config");
import { arrowRightIcon, arrowRightBlack, cashBnc, premiumBnc, pointsBnc } from "./assets";
// Customizable Area Start
// Customizable Area End
class CreditStore extends React.Component {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
        <Grid container className={classes?.offerSidebarBg_creditStore}>
            <Grid item sm={8} xs={12} className={classes?.leftSidebar_creditStore}>
                <Grid container alignItems={'center'} className={classes?.parentContainer_creditStore}>
                    <Grid item xs={3}>
                        <Box className={classes?.backIcon_creditStore}>
                        <img
                            src={arrowRightIcon}
                            data-test-id = "creditStoreNavigation"
                            className={classes?.editProfileArrowIcon_creditStore}
                            onClick={()=>this.props.history?.push("/UserSettings/Settings/OfferAndDiscounts")}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>    
                        <p className={classes?.labelSetting_creditStore}>{configJSON.creditStore}</p>
                    </Grid>
                </Grid>
                <Grid container className={classes?.balanceWrapContainer_creditStore}>
                    <Grid item xs={6}>
                        <Typography variant="h4">Credit Balance</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4" 
                         className={classes?.rightCheck_creditStore}
                         data-test-id="checkScore"
                         onClick={()=>this.props.history?.push("/UserSettings/Settings/OfferAndDiscounts/CreditCashBalance")}>Check
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes?.balanceWrapBack_creditStore}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'}  className={classes?.balanceWrapContent_creditStore}>
                            <Box className={classes?.balanceWrapIcon_creditStore}>
                                <img src={cashBnc} alt="Balance"/>
                            </Box>
                            <Box>
                                <Typography variant="h6">PRINTSEC Cash</Typography>
                                <Typography variant="subtitle1">Earned from promotions & offer at PRINTSEC with limited validity. Use upto 100% on every purchase</Typography>
                            </Box>
                        </Box>
                            <img
                            src={arrowRightBlack}
                            className={classes?.editProfileArrowIcon_creditStore} alt="Arrow"/>
                    </Grid>
                    <Grid item xs={12} className={classes?.balanceWrapBack_creditStore}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'}  className={classes?.balanceWrapContent_creditStore}>
                            <Box className={classes?.balanceWrapIcon_creditStore}>
                                <img src={pointsBnc} alt="Balance"/>
                            </Box>
                            <Box>
                                <Typography variant="h6">Credit Points</Typography>
                                <Typography variant="subtitle1">Earned from promotions & offer at PRINTSEC with limited validity. Use upto 100% on every purchase</Typography>
                            </Box>
                        </Box>
                            <img
                            src={arrowRightBlack}
                            className={classes?.editProfileArrowIcon_creditStore} alt="Arrow"/>
                    </Grid>
                    <Grid item xs={12} className={classes?.balanceWrapBack_creditStore}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'}  className={classes?.balanceWrapContent_creditStore}>
                            <Box className={classes?.balanceWrapIcon_creditStore}>
                                <img src={premiumBnc} alt="Balance"/>
                            </Box>
                            <Box>
                                <Typography variant="h6">Premium member</Typography>
                                <Typography variant="subtitle1">Earned from promotions & offer at PRINTSEC with limited validity. Use upto 100% on every purchase</Typography>
                            </Box>
                        </Box>
                            <img
                            src={arrowRightBlack}
                            className={classes?.editProfileArrowIcon_creditStore} alt="Arrow"/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    offerSidebarBg_creditStore: {
        backgroundColor: "#f1f1f1",
        height: "100%",
    },
    leftSidebar_creditStore: {
        margin: '0 auto',
    },
    sofiaFont: {
        fontFamily: APP_FONTS.SofiaProRegular
    },
    parentContainer_creditStore: {
        padding: "16px",
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    backIcon_creditStore: {
        height: '48px',
        width: '48px',
        backgroundColor: '#32795f',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        cursor: 'pointer',
        transform: 'rotate(180deg)',
    },
    editProfileArrowIcon_creditStore: {
        width: "21px",
        height: "21px",
        margin: '0 15px',
    },
    labelSetting_creditStore: {
        fontFamily: APP_FONTS.SofiaProRegular,
        fontSize: "20px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.8,
        letterSpacing: "normal",
        textTransform: 'uppercase',
        color: "#1e1f20",
        marginBottom: 0,
        textAlign: 'center'
    },
    balanceWrapContainer_creditStore: {
        padding: 30,
        backgroundColor: '#a9fde5',
        marginBottom: 20,
        '& h4': {
            color: '#32795f',
            fontFamily: APP_FONTS.SofiaProRegular,
            fontSize: "26px",
            fontWeight: 700,
        }
    },
    rightCheck_creditStore: {
        textAlign: 'right',
        cursor: 'pointer',
    },
    balanceWrapBack_creditStore: {
        cursor: 'pointer',
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 15,
        marginBottom: 20,
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#32795f',
            color: '#fff',
            '& .MuiTypography-subtitle1': {
                color: '#fff',
            },
        }
    },
    balanceWrapContent_creditStore: {
        gap: 15,
        '& h6': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.4,
            fontFamily: APP_FONTS.SofiaProRegular,
        },
        '& .MuiTypography-subtitle1': {
            fontWeight: 400,
            color: '#8f92a1',
        }
    },
    balanceWrapIcon_creditStore: {
        backgroundColor: '#fdd001',
        height: 34,
        width: 34,
        minWidth: 34,
        borderRadius: 10,
        '& img': {
            height: '100%',
            width: '100%',
        }
    }
  });
  // Customizable Area End

  const CreditStoreBlockWithLoader = withLoader(CreditStore)
  const CreditStoreBlockWithToast = withToast(CreditStoreBlockWithLoader)
  export {CreditStore}
  export default withRouter(withStyles(styles, { withTheme: true })(CreditStoreBlockWithToast));
