//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
// Customizable Area Start

// Customizable Area End

export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  loading: boolean;
  token: string;
  referral_code : string;
  isCopied: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReferAndEarnController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostreferralCodeCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.AlertMessage),
    getName(MessageEnum.RestAPIResponceDataMessage)
];
    this.state = {
      loading: false,
      token: "",
      referral_code: "",
      isCopied: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {

      let AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiPostreferralCodeCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({
            referral_code: responseJson.data.attributes.referral_code,
          });
        }
        else {
          this.props.showNotification(responseJson.errors, 'error');
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentWillUnmount() {
    clearInterval(this.handleTimeInterval)
  }

  getReferralCode() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.token
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostreferralCodeCallId = requestMessage.messageId;

    let getReferralCodeAPIEndPoint = configJSON.getReferralCodeAPIEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getReferralCodeAPIEndPoint)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  // Customizable Area End
}