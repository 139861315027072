//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
import StorageProvider from '../../../framework/src/StorageProvider';
import { printIcon } from "./assets";
// Customizable Area Start

// Customizable Area End

export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  email: string;
  showCalendar: boolean
  dateRange: object;
  open: any;
  loading: boolean;
  selectedOption: any;
  rows: any;
  totalAmount: number;
  razorpay_order_id: string;
  razorpay_payment_id: string;
  razorpay_signature: string;
  token: string;
  isIframeVisible: any;
  invoicePdfUrl: any;
  view:any;
  payment_via:any;
  monthlyInvoiceId:any;
  orderIsCreated:any;
  rowData:any;
  upiId: string;
  step: any;
  payWithUpi: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MerchantinvoicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetinvoicesCallId: String = "";
  apiCreateInvoicesTransactionCallId: String ="";
  apiVerifySecurityKeysCallId: string = "";
  apiPayWithUpiStatusCallId: String = "";
  handleTimeInterval: null;
  apiCheckUpiStatusCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.AlertMessage)];

    this.state = {
      open: false,
      email: "",
      showCalendar: false,
      dateRange: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
      selectedOption: null,
      loading: false,
      rows: [],
      totalAmount: 0.0,
      razorpay_order_id: "",
      razorpay_payment_id: "",
      razorpay_signature: "",
      token: "",
      isIframeVisible: false,
      invoicePdfUrl: "",
      view:false,
      payment_via:"",
      monthlyInvoiceId:"",
      orderIsCreated:true,
      rowData:{},
      upiId: '',
      step: 1,
      payWithUpi: false
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetinvoicesCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({
            rows: responseJson.data,
          });
        }
        else {
          this.props.showNotification(responseJson.errors, 'error');
        }
      }

      else if (apiRequestCallId === this.apiCreateInvoicesTransactionCallId) {
        if (responseJson && responseJson.data) {
          this.props.hideLoader();
          this.setState({
            razorpay_order_id: responseJson.data?.attributes?.razorpay_order_id,
          }, () => {
            if(this.state.razorpay_order_id){
              this.openRazorPay()
            }
          })
        }
        else if (responseJson && responseJson.message) {
          setTimeout(() => {
            this.props.showNotification(responseJson.message, "error");
          }, 0);
        }
        else {
          //Check Error Response
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
        }
        this.props.hideLoader();
      }
      else if (apiRequestCallId == this.apiVerifySecurityKeysCallId) {
        this.setState({ loading: false });
        if (responseJson?.data) {
          this.props.showNotification(responseJson?.meta?.message, "success")
          this.setState({ open: false });
        } 
        else {
          this.props.showNotification(responseJson?.message || responseJson?.error, "error")
        }
        await this.setState({ loading: false })
        this.props.hideLoader()
        this.invoices()
      }
      else if (apiRequestCallId === this.apiPayWithUpiStatusCallId) {
        if (responseJson && responseJson.data) {
          this.handleTimeInterval = setInterval(() => this.checkUpiStatus(), 10000);
          await StorageProvider.remove('transactionId');
          await StorageProvider.set('transactionId', responseJson.data?.id);
          // this.setState({ payWithUpi: false });
        } else if (responseJson && responseJson.message) {
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.message);
          }, 100);
          this.props.showNotification(responseJson.message, "error");
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      else if (apiRequestCallId === this.apiCheckUpiStatusCallId) {
        this.setState({ loading: true, payWithUpi: false });
        if (responseJson && responseJson.data) {
          if (responseJson.data.transaction_status === "SUCCESS") {
            this.props.hideLoaderPayment();
            this.setState({ loading: false, open: false });
            clearInterval(this.handleTimeInterval);
            this.props.showNotification(responseJson.message, "success");
          }
          if (responseJson.data.transaction_status === "FAILURE") {
            this.props.hideLoaderPayment();
            this.setState({ loading: false, open: false, payWithUpi: false });
            clearInterval(this.handleTimeInterval);
            this.props.showNotification(responseJson && responseJson.data.message, "error");
          }
        } else if (responseJson && responseJson.data && responseJson.data.message) {
          this.props.hideLoaderPayment();
          clearInterval(this.handleTimeInterval);
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.data.message);
          }, 100);
        }
        else {
          //Check Error Response
          this.props.hideLoaderPayment();
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
        }

      }


    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleChange = (selectedOption: any) => {
    this.setState({ selectedOption });
  };

  handleClickOpen = () => {
    this.setState({ open: true,step:1 });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseView = () => {
    this.setState({ view: false });
  };
  handleViewOpen = () => {
    this.setState({ view: true });
  };
  handleInputChange = (value: any) => {
    this.setState({ upiId: value });
  }
  nextStep = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  }

  prevStep = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
  }

  componentWillUnmount() {
    clearInterval(this.handleTimeInterval)
  }


  validateUPIId = () => {
    let upiReg = /^\w.+@\w+$/;
    let result = upiReg.test(this.state.upiId);
    return result
  }

  payWithUpiMethod = async () => {
    if (!this.validateUPIId()) {
      this.props.showNotification('Invalid UPI Id', 'error');
      return
    }

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.token,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.token : ""
    };

    const data = {
      "monthly_invoice_id": Number(this.state.monthlyInvoiceId),
      "payerva": this.state.upiId,
      "payment_via": "Upi"
    }

    const httpBody = {
      data: data
    }

    this.setState({ loading: true, payWithUpi: false });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPayWithUpiStatusCallId = requestMessage.messageId;

    let payWithUpiAPIEndPoint = configJSON.payment_via_upi_for_monthly_transaction

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), payWithUpiAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  checkUpiStatus = async () => {

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.token,
      // uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.token : ""
    };

    const getTransactionId = await StorageProvider.get('transactionId');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCheckUpiStatusCallId = requestMessage.messageId;

    let checkUpiStatusAPIEndPoint = `bx_block_ordermanagement/monthly_invoice_transactions/${getTransactionId}/get_upi_monthly_transaction_status`

    this.setState({ loading: true, payWithUpi: false });
    this.props.showLoaderPayment();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), checkUpiStatusAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  createTransaction = () => {
    this.props.showLoader();
    this.setState({ payment_via: "Razorpay", open: false }, () => {
      if (this.state.orderIsCreated ) {
        this.createTransactionApiCall()
      }
    });
    this.props.hideLoader()
  };

  invoices() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.token
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetinvoicesCallId = requestMessage.messageId;

    let getinvoicesAPIEndPoint = configJSON.getinvoicesAPIEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getinvoicesAPIEndPoint)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openRazorPay = async () => {
    const self = this;
    let email = "";
    let name = "";
    let contact = "";
    await StorageProvider.get('loginData').then((res: any) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        email = loginData.email,
          name = loginData.name,
          contact = loginData.phone;
      }
    });
    //@ts-ignore
    const orderAmount = parseFloat(this.state.totalAmount).toFixed(2) * 100;
    var options = {
      description: 'PrintSec Secure Printing',
      image: printIcon,
      currency: 'INR',
      key: window.location.hostname === "www.printsec.app" ? 'rzp_live_zAJUdwu6bC5JXG' : 'rzp_test_MnPssPE2QhSVO2',
      order_id: this.state.razorpay_order_id,
      amount: `${Math.round(orderAmount)}`,
      handler: function (response: any) {
        self.setState({
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response?.razorpay_order_id,
          razorpay_signature: response?.razorpay_signature
        }, () => {
          self.verifySignatureApiCall()
        });

        self.props.showLoader();
      },
      name: 'Printsec pvt ltd',
      prefill: {
        email: email,
        contact: contact,
        name: name
      },
    }
    //@ts-ignore
    var rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response: any) {
      self.props.showNotification(`${response.error.description}`, "error")
    });
    rzp1.open();

    StorageProvider.set('isRazorpayOpen', 'true');
  }

  async verifySignatureApiCall() {
    const paymentVia = this.state.payment_via;
    const monthly_invoice_id= this.state.monthlyInvoiceId
    

   const header = {
     "Content-Type": configJSON.contentType,
     "token": this.state.token,
     uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.token : ""

   };

   const data = {
     transaction_type: "paid",
     monthly_invoice_id: monthly_invoice_id,
     razorpay_order_id: this.state.razorpay_order_id || "",
     razorpay_payment_id: this.state.razorpay_payment_id || "",
     razorpay_signature: this.state.razorpay_signature || ""
   };

   const httpBody = {
     data: data
   };

    this.setState({ loading: true });

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.apiVerifySecurityKeysCallId = requestMessage.messageId;

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_ordermanagement/monthly_invoice_transactions/monthly_invoice_verify_signature"
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.postAPiEndMethod
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
 }
  async createTransactionApiCall() {
    const paymentVia = this.state.payment_via;
    const monthly_invoice_id= this.state.monthlyInvoiceId
   
   const header = {
     "Content-Type": configJSON.contentType,
     "token": this.state.token,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.token : ""

  };

  const data = {
    payment_via: "Razorpay",
    monthly_invoice_id:monthly_invoice_id
  };
   const httpBody = {
        data: data
   };

 this.setState({ loading: true });
 this.props.showLoader();

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.apiCreateInvoicesTransactionCallId = requestMessage.messageId;

let createTransactionAPIEndPoint = ('' + configJSON.postInvoicesApiEndPoint)

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  createTransactionAPIEndPoint
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.postAPiEndMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);

}


  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  // Customizable Area End
}