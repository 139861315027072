// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withToast } from "../../../components/src/withSnackBar.Web";
import CreditCashBalanceController, {
    Props,
    configJSON
  } from "./CreditCashBalanceController.Web";
import { arrowRightIcon, arrowRightBlack, ticketLeafBnc, referEarnBnc, pointsBnc } from "./assets";
// Customizable Area Start
// Customizable Area End
class CreditCashBalance extends CreditCashBalanceController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          token: loginData.token,
        });
      }
    });
    this.getCreditScores();
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
        <Grid container className={classes?.offerSidebarBg_creditStoreBal}>
            <Grid item sm={8} xs={12} className={classes?.leftSidebar_creditStoreBal}>
                <Grid container alignItems={'center'} className={classes?.parentContainer_creditStoreBal}>
                    <Grid item xs={3}>
                        <Box className={classes?.backIcon_creditStoreBal}>
                        <img
                            src={arrowRightIcon}
                            data-test-id="navigationCreditCash"
                            className={classes?.editProfileArrowIcon_creditStoreBal}
                            onClick={()=>this.props.history?.push("/UserSettings/Settings/OfferAndDiscounts")}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <p className={classes?.labelSetting_creditStoreBal}>{configJSON.creditStore}</p>
                    </Grid>
                </Grid>
                <Grid container className={classes?.parentBalanceContainer_creditStoreBal}>
                    <Grid item xs={12}>
                        <Box className={classes?.creditValues}>
                            <Box className={classes?.creditValuesBalance}>
                                <img src={ticketLeafBnc} alt="Coupan" className={classes?.creditImages}/>
                                <Box className={classes?.creditValuesText}>
                                    <Typography variant="h4">Credit Cash Balance</Typography>
                                    <Typography variant="h1">₹{this.state.creditScores}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes?.creditValuesBalance}>
                                <img src={ticketLeafBnc} alt="Coupan" className={classes?.creditImages}/>
                                <Box className={classes?.creditValuesText}>
                                    <Typography variant="h4">Credit Point Balance</Typography>
                                    <Typography variant="h1">₹24.80</Typography>
                                </Box>
                            </Box>
                            <Button variant="outlined" className={classes?.orderNowBtnY_creditStoreBal}>Order Now</Button>
                            <Typography variant="h6">Order Now to redeem these amount or points</Typography>
                            <Typography variant="h5">T&C<sup>*</sup></Typography>
                        
                            <img src={referEarnBnc} alt="Refer Earn" className={classes?.creditImages}/>
                        </Box>
                    </Grid>                    
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes?.balanceWrapBack_creditStoreBal}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'}  className={classes?.balanceWrapContent_creditStoreBal}>
                            <Box className={classes?.balanceWrapIcon_creditStoreBal}>
                                <img src={pointsBnc} alt="Balance"/>
                            </Box>
                            <Box>
                                <Typography variant="h6">How to earn Credit Points</Typography>
                                <Typography variant="subtitle1">Earned from promotions & offer at PRINTSEC with limited validity. Use upto 100% on every purchase</Typography>
                            </Box>
                        </Box>
                            <img
                            src={arrowRightBlack}
                            className={classes?.editProfileArrowIcon_creditStoreBal} alt="Arrow"/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    offerSidebarBg_creditStoreBal: {
        backgroundColor: "#f1f1f1",
        height: "100%",
    },
    leftSidebar_creditStoreBal: {
        margin: '0 auto',
    },
    sofiaFont: {
        fontFamily: APP_FONTS.SofiaProRegular
    },
    parentContainer_creditStoreBal: {
        padding: "16px",
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    backIcon_creditStoreBal: {
        height: '48px',
        width: '48px',
        backgroundColor: '#32795f',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        cursor: 'pointer',
        transform: 'rotate(180deg)',
    },
    editProfileArrowIcon_creditStoreBal: {
        width: "21px",
        height: "21px",
        margin: '0 15px',
    },
    labelSetting_creditStoreBal: {
        fontFamily: APP_FONTS.SofiaProRegular,
        fontSize: "20px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.8,
        letterSpacing: "normal",
        textTransform: 'uppercase',
        color: "#1e1f20",
        marginBottom: 0,
        textAlign: 'center'
    },
    balanceWrapBack_creditStoreBal: {
        cursor: 'pointer',
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 15,
        marginBottom: 20,
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#32795f',
            color: '#fff',
            '& .MuiTypography-subtitle1': {
                color: '#fff',
            },
        }
    },
    balanceWrapContent_creditStoreBal: {
        gap: 15,
        '& h6': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.4,
            fontFamily: APP_FONTS.SofiaProRegular,
        },
        '& .MuiTypography-subtitle1': {
            fontWeight: 400,
            color: '#8f92a1',
        }
    },
    balanceWrapIcon_creditStoreBal: {
        backgroundColor: '#fdd001',
        height: 34,
        width: 34,
        minWidth: 34,
        borderRadius: 10,
        '& img': {
            height: '100%',
            width: '100%',
        }
    },
    parentBalanceContainer_creditStoreBal: {
        borderRadius: '30px',
        width: '94%',
        backgroundColor: '#a9fde5',
        margin: '0 auto 20px',
        padding: 30,
        minHeight: 300,
        textAlign: 'center',
    },
    orderNowBtnY_creditStoreBal: {
        borderColor: '#32795f',
        color: '#32795f',
        textTransform: 'capitalize',
        fontFamily: APP_FONTS.SofiaProRegular,
        fontWeight: 700,
        fontSize: 16,
        padding: '2px 8px',
    },
    creditImages: {
        width: '92%',
        padding: '20px 0'
    },
    creditValues: {
        '& .MuiTypography-root': {
            fontFamily: APP_FONTS.SofiaProRegular,
        },
        '& .MuiTypography-h6': {
            fontWeight: 400,
            color: '#8f92a1',
            margin: '5px 0',
            fontSize: 16,
        },
        '& .MuiTypography-h5': {
            fontWeight: 400,
            color: '#32795f',
        },
    },
    creditValuesBalance: {
        position: 'relative',
    },
    creditValuesText: {
        position: 'absolute',
        top: '54%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        width: '100%',
        '& .MuiTypography-h4': {
            fontWeight: 700,
            fontSize: 24,
        },
        '& .MuiTypography-h1': {
            fontWeight: 700,
            fontSize: 60,
        }
    }
  });
  // Customizable Area End

  const CreditCashBalanceBlockWithLoader = withLoader(CreditCashBalance)
  const CreditCashBalanceBlockWithToast = withToast(CreditCashBalanceBlockWithLoader)
  export {CreditCashBalance}
  export default withRouter(withStyles(styles, { withTheme: true })(CreditCashBalanceBlockWithToast));
