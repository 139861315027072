//@ts-nocheck
import React from "react";
// Customizable Area Start

// Customizable Area End

import ScannedOrderDetailsController, {
  Props,
  configJSON,
} from "./ScannedOrderDetailsController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import withRouter from "react-router/withRouter";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import OTPInputView from "../../../components/src/OtpInputDownload.web";
import Moment from "moment";
import printJS from 'print-js';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  checkbox_empty,
  checkbox_marked,
  activeDownload,
  passiveDownload,
  textFileIcon,
  pendingPayment
} from "./assets";


class ScannedOrderDetails extends ScannedOrderDetailsController {
  // Customizable Area Start
  timer: null;
  otpReference = React.createRef(null);
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          authToken: loginData.token,
          fullPhoneNumber: loginData.phone,
        });
      }
    });

    await StorageProvider.get("scannedOrderDetail").then((res) => {
      const orderId = JSON.parse(res);
      if (orderId) {
        this.setState({
          orderId: orderId,
        });
      }
    });

    await this.getOrderDetailsApiCall(false);
    await this.getExpiryTime()
    const MINUTE_MS = 15000;
    const interval = setInterval(() => {
      if (this.state.isOrderDetailsResponse && this.state.orderDetail.status === 'Pending') {

        this.getOrderDetailsApiCall(false);
      }
    }, MINUTE_MS);
    return () => clearInterval(interval);
    // Customizable Area End
  }

  // Customizable Area Start

  _onDownloadFileClicked = () => {
    if (this.state.isDownloadClicked) {
      const selectedDocs = this.state.orderAttachment.filter((attachment) => {
        return attachment.isSelected;
      });
      if (selectedDocs.length > 0) {
        this.getOtpToDownloadDocs();
        this.setState({ openModel: true });
      } else {
        this.props.showNotification("Please select at least 1 doc", "warn");
      }
    } else {
      this.setState({
        isDownloadClicked: true,
      });
    }
  };
  async componentWillUnmount() {
    clearInterval(this.timer);
  }
  completeOrder = (event: any) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.updateOrderStatus(true);
      }
    };
    this.props.openDialog(
      "Complete Order",
      "All Set? Are you sure want to Complete this order?",
      onClose
    );
  };


  _selectedItem = (selectedAttachment: any) => {
    let orderAttachment = [...this.state.orderAttachment];
    let attachmentIndex = orderAttachment.findIndex((attachment) => {
      return selectedAttachment.id === attachment.id;
    });
    let selectedAttachmentItem = orderAttachment[attachmentIndex];
    selectedAttachmentItem = {
      ...selectedAttachmentItem,
      isSelected: !selectedAttachmentItem?.isSelected,
    };
    orderAttachment[attachmentIndex] = selectedAttachmentItem;
    this.setState({ orderAttachment: orderAttachment });
  };

  calculateHours=(createdAt:any)=>{
    let now = new Date().getTime();
    let countDownTime = new Date(createdAt)
    countDownTime = countDownTime.setHours(countDownTime.getHours() + this.state.expiryTime)
    let distance = countDownTime - now;
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    hours = hours.toString().length > 1 ? hours : "0" + hours;
    minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
    const time = "Expiry In " + hours + ":" + minutes + " hours";
    return time;
  }
  getExpiryDateOfDoc = (createdAt: any, orderStatus) => {
    if (orderStatus === "Completed" || orderStatus === "Printing" || orderStatus === "Printing Completed") {
      return "";
    }
    if (createdAt != undefined) {
      return this.calculateHours(createdAt);
    }
  };

  getOtp(OTP: string) {
    this.setState({ OTP });
  }

  manageTimer = () => {
    if (this.state.counter > 0) {
      this.setState(
        ({ counter }) => ({
          counter: counter - 1,
        }),
        () => {
          if (this.state.counter === 0) {
            clearInterval(this.timer);
          }
        }
      );
    }
  };
  restartTimer = () => {
    if (this.state.restartTimer) {
      this.setState({
        counter: 120,
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false,
      });
    }
  };
  resendOTP = () => {
    if (this.state.counter === 0) this.getOtpToDownloadDocs();
  };
  async downloadURI(url, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
      })
    );
    document.body.removeChild(link);
  }

  _downloadFiles = async () => {
    const selectedDocs = this.state.orderAttachment.filter((attachment) => {
      return attachment.isSelected;
    });
    if (selectedDocs.length > 0) {
      selectedDocs.forEach((docObj: any) => {
        this.downloadURI(docObj.pdf_url, docObj.file_name);
      });
    }
    this.props.history.push("/MerchantOrderManagement/OrderHistory/History");
  };

  goBackToPrinting = () => {
    this.setState({ openAllPrintModel: false, documentSelected: false })
  }

  finishPrinting = (event) => {
    event.preventDefault();
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.printingFinshedOrderUpdate();
      }
    };
    this.props.openDialog(
      "Printing Finish Confirmation",
      "Are you sure want to finish Printing Docs?",
      onClose
    );
  };

  updateOrderStatusToPrintingConfirmBox = (event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.updateOrderStatusToPrinting();
      }
    };
    this.props.openDialog(
      "Print Confirmation",
      "Are you sure want to Print All Documents?",
      onClose
    );
  };

  resetAndResendOTP() {
    if (this.otpReference.current) {
      this.otpReference.current.resetOTP();
    }
  }
  reset = () => {
    this.resendOTP();
  };

  printDocument = (attachment) => {
    if (attachment?.content_type === "image/gif" ||
      attachment?.content_type === "image/png" ||
      attachment?.content_type === "image/jpg" ||
      attachment?.content_type === "image/jpeg") {
      this.printImage(attachment);
    } else {
      const pdfUrl = attachment?.pdf_url;
      if (pdfUrl) {
        printJS({
          printable: pdfUrl,
          type: 'pdf',
          onPrintDialogClose: () => this.onPrintDialogClose(attachment),
        });
}
    }
    // this.printedDocument(attachment.id);
  }

  callPrint = (farmeId) => {
    let PDF = document.getElementById(farmeId);
    PDF?.focus();
    PDF?.contentWindow.print();
  }

  onloadingStart = () => {
    setTimeout(function () {
      (window).addEventListener('mousemove', window.onafterprint);
    }, 1);
  }

  onPrintDialogClose = (attachment) => {
        this.printedDocument(attachment.id);
  }

  printImage = (attachment) => {
    let self = this;
    let frame = document.createElement('iframe');
    frame.id = "hidden_iframe";
    let external_doc = new Blob([`<html><body><img style="position:fixed; max-width:100%; max-height:100%" src=${attachment.pdf_url}></body></html>`], {
      type: 'text/html',
    });
    frame.onload = () => {
      try {
        window.onafterprint = (e) => {
          (window).removeEventListener('mousemove', window.onafterprint);
              this.printedDocument(attachment.id);
          };
        // this.contentWindow && this.contentWindow.print();
        frame.contentWindow.print();
        setTimeout(function () {
          (window).addEventListener('mousemove', window.onafterprint);
        }, 1);
        return;
      } catch (e) { }
      frame.parentNode.removeChild(frame);
    };
    frame.src = URL.createObjectURL(external_doc);
    document.body.appendChild(frame);
  }

  handleDocumentsState = (len) => {
    const someArr = new Array(len).fill(0)
    let someObj = {}
    someArr.forEach((item, index) => {
      let someOtherObj = { [index]: false }
      someObj = { ...someObj, ...someOtherObj }
    })
    this.setState({ documentSelected: someObj }, () => {
    })
  }

  updateDocumentState = (index) => {
    const state = this.state.documentSelected
    if (state[index]) {
      for (const key in state) {
        state[key] = false
      }
      this.setState({ documentSelected: state }, () => {
      })
    } else {
      for (const key in state) {
        state[key] = false
      }
      let someobj = {
        ...state,
        [index]: !state[index]
      }
      this.setState({ documentSelected: someobj }, () => {
      })
    }
  }

  findSelectedDocUrl = () => {
    for (const key in this.state.documentSelected) {
      if (this.state.documentSelected[key]) {
        this.printDocument(this.state.orderAttachment[key])
      }
    }
  }

  printButtonEnable = () => {
    for (const key in this.state.documentSelected) {
      if (this.state.documentSelected[key]) {
        return false;
      }
    }
    return true;
  }

  getFileTypeIcon = (content_type: any) => {
    let imgSrc;

    switch (content_type) {
      case "image/gif":
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        imgSrc = require("../assets/image_placeholder.jpg");
        break;
      case "application/pdf":
        imgSrc = require("../assets/pdf_placeholder.png");
        break;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.oasis.opendocument.presentation":
        imgSrc = require("../assets/ppt.png");
        break;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.oasis.opendocument.spreadsheet":
        imgSrc = require("../assets/docexcel.png");
        break;
      case "text/plain":
        imgSrc = textFileIcon;
        break;
      case "application/msword":
      case "application/vnd.oasis.opendocument.text":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        imgSrc = require("../assets/docword.png");
        break;
      default:
        imgSrc = require("../assets/pdf_placeholder.png");
    }

    return imgSrc
  }

  getOrderStatus = (order_number: any, orderStatus: any) => {
    let message;

    if (order_number === undefined) {
      message = " ";
    } else {

      switch (orderStatus) {
        case "Printing":
        case "Printing Completed":
        case "Completed":
        case "Cancelled":
        case "Refunded":
        case "Transactionfailed":
        case "Concern Raised":
          message = `No files are visible because order is ${orderStatus || this.state.orderDetail.status}`;
          break;
        default:
          break;
      }
    }

    return message;

  }

  getCustomerName(customer_details) {
    let message = '';
    const customerDetails = customer_details;
    if (customerDetails) {
      if (customerDetails.account_type === 'GuestAccount') {
        message = 'Guest';
      } else if (customerDetails.name) {
        message = customerDetails.name;
      }
    }

    return message;

  }

  handleOrderHeader=()=>{
    const tableData = ["Download","Content Type","File Name","All Pages","Page Layout","Copies","Print","Paper Type","Button"]
    return tableData.map((item)=>(
      item === "Download" && this.state.isDownloadClicked ? <th>{item}</th> : item != "Download" && <th>{item}</th> 
    ))
  }

  getOrderDetailStatus(orderDetail) {
    let message = '';
    const { status: orderStatus } = orderDetail;

    switch (orderDetail.status) {
      case "Pending":
        message = "Pending for Payment";
        break;
      case "Completed":
        message = "Completed";
        break;
      case "Printing":
        message = "Printing";
        break;
      case "Printingcompleted":
        message = "Printing Completed";
        break;
      case "Paymentcompleted":
        message = "Payment Completed";
        break;
      case "Refunded":
        message = "Refunded";
        break;
      case "Cancelled":
        message = "Cancelled";
        break;
      case "Concernraised":
        message = "Concern Raised";
        break;
      default:
        message = orderDetail.status;
        break;
    }

    return message;

  }
  handleRestartTimer() {
    if (this.state.restartTimer) {
      this.restartTimer();
    }
  }

  handleOtpVerification() {
    if (this.state.otpVerified) {
      this.setState({ otpVerified: false });
      clearInterval(this.timer);
      this._downloadFiles();
    }
  }
  handleOrderNumber(){
    return this.state.orderDetail.order_number &&`Order #${this.state.orderDetail.order_number}`
  }

  handleOrderCreatedAt(){
    return this.state.orderDetail.created_at &&
      Moment(this.state.orderDetail.created_at).format(
        "DD/MM/yyyy hh:mm A"
      )
  }

  renderTotalAmount() {
    const { orderDetail } = this.state;

    if (orderDetail.total_amount > 0) {
      const formattedAmount = `Rs.${parseFloat(orderDetail.total_amount.toString()).toFixed(2)}`;

      if (orderDetail.status === "Pending") {
        return `Total Amount: ${formattedAmount}`;
      } else {
        return `Total Paid: ${formattedAmount}`;
      }
    }

    return null;
  }

  shouldDisplayTotalDocs() {
    const { orderDetail } = this.state;
    const excludedStatuses = ['Autocancel', 'Completed', 'Printingcompleted', 'Transactionfailed'];

    if (
      !excludedStatuses.includes(orderDetail.status) &&
      orderDetail.total_docs
    ) {
      return `${orderDetail.total_docs} Documents`;
    }

    return null;
  }

  getDownloadComponent() {
    return this.state.isDownloadClicked ? activeDownload : passiveDownload;
  }

  getDownloadText(isDownloadClicked, selectedDocs) {
    return isDownloadClicked
      ? `Download Selected Files (${selectedDocs.length})`
      : "Select File To Download";
  }

  getAndCheckAttachementsDetails(inputData,fieldName){
    return inputData[fieldName] ? inputData[fieldName]: ""
  }
  renderResendLink(counter, resendOTP) {
    if (counter !== 0) {
      const minutes = Math.floor(counter / 60);
      const seconds = counter % 60;
  
      const formattedSeconds = seconds.toString().length > 1 ? seconds : "0" + seconds;
  
      return (
        <span style={{ textDecoration: "underline", cursor: "pointer", marginLeft: 7 }}>
          Resend In 0{minutes}:{formattedSeconds}
        </span>
      );
    } else {
      return (
        <span
          style={{ textDecoration: "underline", cursor: "pointer", marginLeft: 7 }}
          onClick={resendOTP}
        >
          Resend OTP
        </span>
      );
    }
  }

  getOrderStatusMessage(orderAttachment) {
    const isAllPrinted =
      orderAttachment?.filter((ele) => ele.is_printed === false).length === 0;
  
    return isAllPrinted ? "All Set" : "Printing Documents";
  }

  getPrintingStatusMessage(orderAttachment) {
    const isAnyNotPrinted =
      orderAttachment?.filter((ele) => ele.is_printed === false)?.length > 0;
  
    if (isAnyNotPrinted) {
      const printedCount = orderAttachment?.filter((ele) => ele.is_printed === true)?.length || 0;
      return `${printedCount}/${orderAttachment?.length || 0}`;
    } else {
      return `Printed ${orderAttachment?.length || 0} Docs`;
    }
  }

  isOrderPendingOrCompleted(orderStatus) {
    return orderStatus !== "Pending for Payment" && orderStatus === "Payment Completed";
  }

  getImageSource(isTrue) {
    return isTrue
      ? require("../assets/printDocument.png")
      : require("../assets/print.svg");
  }


  determineStatusClass(orderDetailStatus, classes) {
    return orderDetailStatus !== 'Autocancel' ? classes?.totalFilesText : classes?.downloadText;
  }

  renderFinishButtons(orderDetail, orderAttachment, completeOrder, finishPrinting, goBackToPrinting, classes?) {
    const isFinishOrderButtonVisible = false; // Modify this condition if needed
  
    return (
      <>
        {isFinishOrderButtonVisible ? (
          <Button
            style={{ backgroundColor: "#32795f" }}
            className={classes?.closeModalBtn}
            onClick={(e) => completeOrder(e)}
          >
            <span style={{ fontSize: '14px' }}>Finish Order</span>
          </Button>
        ) : (
          <div>
            <Button
              style={{ backgroundColor: "#32795f" }}
              className={classes?.closeModalBtnFinish}
              onClick={finishPrinting}
            >
              <span style={{ fontSize: '14px' }}>Finish Printing</span>
            </Button>
            {orderDetail.total_docs > 1 && orderAttachment.filter((ele) => !ele.is_printed).length > 0 && (
              <Button
                style={{ backgroundColor: "#32795f" }}
                className={classes?.closeModalBtnContinue}
                onClick={goBackToPrinting}
              >
                <span style={{ fontSize: '14px' }}>Continue To Print</span>
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    let orderStatus = "";
  
    this.handleRestartTimer();
    this.handleOtpVerification();
    const selectedDocs = this.state.orderAttachment.filter((attachment) => {
      return attachment.isSelected;
    });
    if (this.state.orderDetail.status != undefined) {
      switch (this.state.orderDetail.status) {
        case "Pending":
          orderStatus = "Pending for Payment";
          break;
        case "Completed":
          orderStatus = "Completed";
          break;
        case "Printing":
          orderStatus = "Printing";
          break;
        case "Printingcompleted":
          orderStatus = "Printing Completed";
          break;
        case "Paymentcompleted":
          orderStatus = "Payment Completed";
          break;
        case "Refunded":
          orderStatus = "Refunded";
          break;
        case "Cancelled":
          orderStatus = "Cancelled";
          break;
        case "Concernraised":
          orderStatus = "Concern Raised";
          break;
        default:
          break;
      }
    }

    return (
      <Grid container className={classes?.rightContent}>
        <Grid item xs={12}>
          <div className={classes?.headingLeft}>
            <img src={require('../assets/backIcon.png')} data-test-id="orderPageNavigation" onClick={() => {
              this.props.history?.push(
                "/MerchantOrderManagement/OrderHistory/History"
              );
            }}
              alt="" className={classes?.backIcon} />
            <Typography className={classes?.pageHeader}>
              Order Detail
            </Typography>
          </div>
        </Grid>
        {
          Object.keys(this.state.orderDetail).length > 0 &&
          <>
            <Grid item xs={12} className={classes?.headingCont}>
              <Typography className={classes?.selectFilesText}>
                {this.handleOrderNumber()}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes?.orderDetailsCont}>
              <Typography className={classes?.orderDetailsName}>
                {this.getCustomerName(this.state.orderDetail?.customer_details)}
              </Typography>
              <Typography className={classes?.orderDetailsText}>
                {this.handleOrderCreatedAt()}
              </Typography>
              <Typography className={classes?.orderDetailsText}>
                {this.getOrderDetailStatus(this.state.orderDetail)}
              </Typography>
              {this.state.orderDetail.status != "Pending" ? "" :
                <Typography className={classes?.orderDetailsText}>
                   {this.getExpiryDateOfDoc(this.state.orderDetail.created_at, orderStatus)}
                </Typography>
              }
              <Typography className={classes?.orderDetailsText}>
                {this.renderTotalAmount()}
              </Typography>
              <Typography className={classes?.orderDetailsText}>
               {this.shouldDisplayTotalDocs()}
              </Typography>
              
            </Grid>
            {this.isOrderPendingOrCompleted(orderStatus) && (
              <Grid
                item
                xs={12}

                container
                justifyContent="flex-end"
                alignItems="center"
              >
                {
                  this.state.orderDetail.customer_details?.account_type !== "GuestAccount" &&
                  <Grid item xs={3} style={{ cursor: "pointer", margin: "20px" }}>              
                    <>
                      <img
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={this._onDownloadFileClicked}
                        src={this.getDownloadComponent()}
                        alt=""
                      />
                      <Typography className={classes?.downloadText}>
                      {this.getDownloadText(this.state.isDownloadClicked, selectedDocs)}
                      </Typography>
                    </>
                  </Grid>
                }
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={this.determineStatusClass(this.state.orderDetail.status, classes)}>
                {this.getOrderStatus(this.state.orderDetail.order_number, orderStatus)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes?.downloadText}>
              {
                this.state.orderDetail.status === 'Autocancel'
                &&
                <Typography className={classes?.totalFilesText}>
                  Order is autocancel so all the documents has been removed
                </Typography>
              }
            </Grid>
            {this.isOrderPendingOrCompleted(orderStatus) && (
              <table className={classes?.orderTable}>
                <tr>
                {
                  this.handleOrderHeader()
              } 
              </tr>
                {this.state.orderAttachment.map((attachment, index) => {
                  return(
                    <tr>
                      {this.state.isDownloadClicked &&
                       <td>
                        <img src={attachment.isSelected ? checkbox_marked : checkbox_empty } alt="" data-test-id="attachFile" onClick={() => {this._selectedItem(attachment)}}/>
                      </td>}
                      <td className={classes?.orderImg}>
                       <img src={this.getFileTypeIcon(attachment?.content_type)} />
                      </td>
                      <td>
                       {this.getAndCheckAttachementsDetails(attachment,"file_name")}
                      </td>
                      <td>
                       {this.getAndCheckAttachementsDetails(attachment,"colour")}
                      </td>
                      <td>
                      {this.getAndCheckAttachementsDetails(attachment,"layout")}
                      {this.getAndCheckAttachementsDetails(attachment,"page_size")}
                      </td>
                      <td>
                      {this.getAndCheckAttachementsDetails(attachment,"total_pages")} {attachment?.total_pages > 1 ? " Pages" : " Page"}
                      </td>
                      <td>
                       {this.getAndCheckAttachementsDetails(attachment,"number_of_copies")}
                      </td>
                      <td>
                        {this.getAndCheckAttachementsDetails(attachment,"paper_type")}
                      </td>
                      <td className={classes?.viewBtns}>
                       <Button
                         onClick={() => { this.printDocument(attachment) }}
                         disabled={attachment.is_printed}
                        >
                          Print
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </table>
            )}
          </>
        }
        {
          this.state.orderDetail.status === "Pending" &&
          <>
            <Grid item xs={12} className={classes?.downloadText}>
              {/* added manual loader */}
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "40%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress size="5rem" style={{ color: "#fdd001" }} />
              </div>
              <img
                src={pendingPayment}
                alt="Payment Pending"
                className={classes?.pendingPaymentIcon}
              />
              <Typography className={classes?.pendingPaymentText}>
                {configJSON.paymentPendingText}
              </Typography>
            </Grid>
          </>
        }
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.openModel}
          data-test-id="completeOrderId"
          onClose={() => {
            this.setState({ openModel: false })
          }}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes?.modalParent}
        >
          <div className={classes?.modalContent}>
            <div className={classes?.modalHeadingCont}>
              <Typography className={classes?.modalHeading}>
                Verify OTP
              </Typography>
            </div>
            <div className={classes?.modalContentCont}>
              <div className={classes?.modalEachDiv}>
                <Typography className={classes?.modalContentText}>
                  Ask your customer for approval by sharing their OTP.
                </Typography>
              </div>
              <div className={classes?.modalEachDiv}>
                <Typography
                  className={classes?.modalContentText}
                  style={{ textAlign: "left" }}
                >
                  OTP:
                </Typography>
              </div>
              <div className={classes?.modalEachDiv}>
                <OTPInputView
                  OTPLength={4}
                  value={this.state.otp}
                  className="change-passcode"
                  otpType={"number"}
                  onOTPChange={(otp) => {
                    this.setState({ otp: otp });
                  }}
                  secure={false} />
              </div>
              <div className={classes?.modalEachDiv}>
                <Typography className={classes?.modalContentText}>
                  Didn’t get the OTP?
                  {this.renderResendLink(this.state.counter, () => this.resendOTP())}
                </Typography>
              </div>
              <div className={classes?.modalEachDiv}>
                <Button
                  className={classes?.closeModalBtn}
                  onClick={this.validateOtpCall}
                  style={{ backgroundColor: "#32795f" }}
                >
                  DOWNLOAD FILES
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.openAllPrintModel}
          onClose={() => {
            this.setState({ openAllPrintModel: false })
          }}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes?.modalParent}
        >
          <div className={classes?.modalContent}>
            <div className={classes?.modalHeadingCont}>
              <Typography className={classes?.modalHeading}>
                Print Order # {this.state.orderDetail.order_number}
              </Typography>
            </div>
            <div className={classes?.modalPrintContent}>
              <img
                src={
                 this.getImageSource(true)
                }
                alt=""
                className={classes?.modalImg}
              />
              <Typography className={classes?.modalContentText}>
               {this.getOrderStatusMessage(this.state.orderAttachment)}
              </Typography>
              <Typography className={classes?.modalContentText}>
              {this.getPrintingStatusMessage(this.state.orderAttachment)}
              </Typography>
                    {this.renderFinishButtons(
                    this.state.orderDetail,
                    this.state.orderAttachment,
                    this.completeOrder,
                    this.finishPrinting,
                    this.goBackToPrinting,
                    classes
                    )}
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.printAllDocumentStarted}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes?.modalParent}
        >
          <div className={classes?.modalContent}>
            <div className={classes?.modalHeadingCont}>
              <Typography className={classes?.modalHeading}>Printing</Typography>
            </div>
            <div className={classes?.modalContentCont}>
              <img
                src={require("../assets/printDocument.png")}
                alt=""
                className={classes?.modalImg}
              />
              <Typography className={classes?.modalContentText}>
                {"All Set"}
              </Typography>
              <Typography className={classes?.modalContentText}>
                {`${this.state.orderAttachment.length} Docs`}{" "}
              </Typography>
              <Button
                style={{ backgroundColor: "#32795f" }}
                className={classes?.closeModalBtn}
                onClick={this.finishPrinting}
              >
                <span style={{ fontSize: '14px' }}>Finish Printing</span>
              </Button>
            </div>
          </div>
        </Modal>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      height: "100%",
      minWidth: 220,
      width: "90%",
      maxWidth: "400px",
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContentCont: {
      display: "flex",
      height: "90%",
      flexDirection: "column",
      alignItems: "center",
    },
    modalPrintContent: {
      display: "flex",
      height: "90%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    modalHeadingCont: {
      height: "10%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    backImg: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    headingCont: {
      width: "100%",
    },
    headingLeft: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0 8px 20px;'
    },
    selectFilesText: {
      fontSize: 22,
      margin: "20px 0 0 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    orderDetailsCont: {
      marginTop: 7,
      width: "100%",
    },
    modalEachDiv: {
      width: "90%",
      margin: "5px auto"
    },
    downloadRightCont: {
      display: "flex",
      cursor: "pointer",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    orderDetailsText: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    orderDetailsName: {
      fontSize: 20,
      margin: "8px 0 8px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    modalContentText: {
      fontSize: 14,
      margin: "10px auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    qrText: {
      fontSize: 16,
      margin: 0,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#8f92a1",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#32795f",
        color: "white",
        borderRadius: 12,
        width: "90%",
        margin: "20px auto 0 0",
        fontFamily: APP_FONTS.SofiaProRegular,
        height: 55,
        maxWidth: 300,
      }
    },
    backgroundImg: {
      background: "#f7f7f7",
      // height:'100%'
      // backgroundImage:`url(require('../assets/background.png'))`,
      // backgroundRepeat:"repeat"
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsCont: {
      width: "95%",
      margin: "0 auto 20px",
    },
    downloadCont: {
      width: "95%",
      margin: "20px auto 10px auto",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    downloadRight: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      // height:250,
      // width:'90%',
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
    },
    printImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
      cursor: "pointer"
    },
    modalImg: {
      height: 70,
      width: 70,
      objectFit: "contain",
    },
    descCont: {
      display: "flex",
      borderRadius: 12,
      backgroundColor: "white",
      padding: "10px 20px",
      width: "90%",
      margin: "auto",
    },
    eachDesc: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      marginTop: "20px",
    },
    uploadCont: {
      margin: "auto",
      marginTop: "20px",
      border: "2px dotted #77c285",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 12,
    },
    uploadImg: {
      height: 130,
      width: 130,
      margin: "auto",
      objectFit: "contain",
    },
    uploadText: {
      fontSize: 22,
      width: "80%",
      margin: "20px auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
      margin: "10px 0",
      wordBreak: 'break-all'
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
    },
    qrImg: {
      height: 70,
      width: 70,
      marginBottom: 10,
      objectFit: "contain",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
    },
    downloadText: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
      marginTop: 10
    },
    totalFilesText: {
      margin: '3%',
      fontSize: 14,
      // fontWeight:"bold",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    btnCont: {
      margin: "auto",
      marginTop: "20px",
      borderRadius: 12,
    },
    qrCont: {
      margin: "auto",
      marginTop: "20px",
      paddingLeft: 20,
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    closeModalBtn: {
      color: "white",
      borderRadius: 12,
      width: "100%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    closeModalBtnFinish: {
      color: "white",
      borderRadius: 12,
      width: "100%",
      margin: "20px auto 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    closeModalBtnContinue: {
      color: "white",
      borderRadius: 12,
      width: "100%",
      // margin: "20px auto 80px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    pageHeader: {
      fontSize: 22,
      marginLeft: "5px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    backIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor: "pointer"
    },
    pendingPaymentIcon: {
      width: 150,
      height: 150,
      objectFit: "contain"
    },
    pendingPaymentText: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#2f2f30",
    },
    orderTable: {
      width: '100%',
      padding: 15,
      '& th': {
        padding: '15px 8px',
        borderTop: '1px solid #ccc',
        '&:first-child': {
          width: 150,
          paddingLeft: 0,
        },
        '&:last-child': {
          textAlign: 'center',
        }
      },
      '& td': {
        padding: '15px 8px',
        borderTop: '1px solid #ccc',
        '&:first-child': {
          paddingLeft: 0,
        },
        '&:last-child': {
          textAlign: 'center',
        },
      }
    },
    viewBtns: {
      '& button': {
        backgroundColor: '#32795f',
        fontSize: 13,
        color: '#fff',
        textTransform: 'capitalize',
        padding: '5px 8px',
        '&.Mui-disabled': {
          backgroundColor: '#808080',
          color: '#fff'
        },
        '&:hover': {
          backgroundColor: '#32795f',
          color: '#fff',
        }
      }
    },
    orderImg: {
      '& img': {
        height: 55,
        width: 55,
      }
    }
  });
export {ScannedOrderDetails}
// Customizable Area End
const ScannedOrderDetailsWithConfirmBox = withConfirmBox(ScannedOrderDetails);
const ScannedOrderDetailsWithLoader = withLoader(
  ScannedOrderDetailsWithConfirmBox
);
const ScannedOrderDetailsWithToast = withToast(ScannedOrderDetailsWithLoader);
export default withRouter(
  withStyles(styles, { withTheme: true })(ScannedOrderDetailsWithToast)
);