// @ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from '../../../framework/src/StorageProvider';
import {
  ToastAndroid,
  Platform
} from "react-native";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { RouteProps } from "react-router";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export type Props =  RouteProps & {
  id: string;
  openDialog : any;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  otp: any;
  currentCountryCode: any;
  loading: boolean;
  phoneNumberError: string;
  otpError: string;
  errorMessage: string;
  isClearCalled: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PhoneLoginController extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start
  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelPasscode: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiGetOTPLCallId: String = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.ReciveUserCredentials),
       getName(MessageEnum.AlertMessage)
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "91",
      enableField: false,
      loading: false,
      otp: "",
      phoneNumberError: "",
      otpError: "",
      errorMessage: "",
      isClearCalled: false
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelPasscode = configJSON.labelPasscode;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    console.log("API RESPONSE===>", message);


    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });

    // }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      runEngine.debugLog("selectedCode", selectedCode);
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode: selectedCode?.value
            // selectedCode.indexOf("+") > 0
            //   ? selectedCode.split("+")[1]
              
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetOTPLCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          //TODO: Need to uncomment this to go with the login flow
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.saveLoggedInUserData(responseJson);

          let data: any = {}
          data.token = responseJson.meta.token;
          data.phone = responseJson.meta.account.data.attributes.full_phone_number;
          data.email = responseJson.meta.account.data.attributes.email;
          data.name = responseJson.meta.account.data.attributes.name;
          data.id = responseJson.meta.account.data.id;
          data.address = responseJson.meta.account.data.attributes.address;
          data.profile_image_path = responseJson.meta.account.data.attributes.profile_image_path;
          data.user_type = responseJson.meta.account.data.attributes.user_type;
          data.is_two_step_verification = responseJson.meta.account.data.attributes.is_two_step_verification;
          data.is_secured_delivery=responseJson.meta.account.data.attributes.business_details.merchant_details?.is_secured_delivery || false
          data.payment_type=responseJson.meta.account.data.attributes.business_details.merchant_details?.payment_type || "Prepaid"
          data.referral_code=responseJson.meta.account.data.attributes.referral_code;

          data.id = responseJson.meta.account.data.id;
          if (responseJson.meta.account.data.attributes.business_details && responseJson.meta.account.data.attributes.business_details.merchant_details && responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
            if (responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
              data.profile_image_path = responseJson.meta.account.data.attributes.business_details.profile_image_path;
              data.shop_name = responseJson.meta.account.data.attributes.business_details.merchant_details.shop_name;
            }
          }
          await StorageProvider.remove('login_type');
          await StorageProvider.remove('loginData');
          await StorageProvider.set('loginData', JSON.stringify(data));
          // this.notifyMessage("Login Success");

          await StorageProvider.remove('businessDetails');

          if (responseJson.meta.account.data.attributes.business_details && responseJson.meta.account.data.attributes.business_details.merchant_details && responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
            if (responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
              let data: any = {}
              data.shop_name = responseJson.meta.account.data.attributes.business_details.merchant_details.shop_name;
              data.owner_name = responseJson.meta.account.data.attributes.business_details.merchant_details.owner_name;
              data.shop_address = responseJson.meta.account.data.attributes.business_details.merchant_details.shop_address;
              data.gst_number = responseJson.meta.account.data.attributes.business_details.merchant_details.gst_number;
              data.bhim_upi_number = responseJson.meta.account.data.attributes.business_details.merchant_details.bhim_upi_number;
              data.email = responseJson.meta.account.data.attributes.business_details.merchant_details.email;
              data.lat = responseJson.meta.account.data.attributes.business_details.merchant_details.lat;
              data.lng = responseJson.meta.account.data.attributes.business_details.merchant_details.lng;
              data.pages = responseJson.meta.account.data.attributes.business_details.merchant_details.page_types;
              data.print_colours = responseJson.meta.account.data.attributes.business_details.merchant_details.print_colours;
              data.profile_image_path = responseJson.meta.account.data.attributes.business_details.profile_image_path;
              data.merchantId = responseJson.meta.account.data.attributes.business_details.merchant_details.id;
              data.paper_types = responseJson.meta.account.data.attributes.business_details.merchant_details.paper_types;
              // data.editPayoutDetails = responseJson.meta.account.data.attributes.business_details.merchant_details.payout_details_editable;
              // data.unlock_setting = responseJson.meta.account.data.attributes.business_details.merchant_details.unlock_setting;
              data.is_setting_locked = responseJson.meta.account.data.attributes.business_details.merchant_details.is_setting_locked;
              
              await StorageProvider.remove('businessDetailsData');

              await StorageProvider.set('businessDetailsData', JSON.stringify(data));

              await StorageProvider.set('businessDetails', "yes");
            } else {
              await StorageProvider.set('businessDetails', "no");
            }
          }
          else {
            await StorageProvider.set('businessDetails', "no");
          }
          await StorageProvider.remove('payoutDetails');
          if (responseJson.meta.account.data.attributes.bank_details && responseJson.meta.account.data.attributes.bank_details.id) {
            let data: any = {}
            data.beneficiary_name = responseJson.meta.account.data.attributes.bank_details.beneficiary_name;
            data.account_number = responseJson.meta.account.data.attributes.bank_details.account_number;
            data.bank_name = responseJson.meta.account.data.attributes.bank_details.bank_name;
            data.ifsc_code = responseJson.meta.account.data.attributes.bank_details.ifsc_code;
            data.bank_address = responseJson.meta.account.data.attributes.bank_details.bank_address;

            await StorageProvider.remove('payoutDetailsData');
            await StorageProvider.set('payoutDetailsData', JSON.stringify(data));
            await StorageProvider.set('payoutDetails', "yes");
          } else
            await StorageProvider.set('payoutDetails', "no");

          if (responseJson.meta.account.data.attributes.user_type == 'Customer')
                this.props.history.push("UserSidebarNavigation");
          else if (responseJson.meta.account.data.attributes.user_type == 'Merchant') {
            if (responseJson.meta.account.data.attributes.business_details && responseJson.meta.account.data.attributes.business_details.merchant_details) {
              if (responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
                if(responseJson.meta.account.data.attributes.business_details.merchant_details.bhim_upi_number){
                  this.props.history.push('MerchantSidebarNavigation');
                } else if (responseJson.meta.account.data.attributes.bank_details && responseJson.meta.account.data.attributes.bank_details.id) {
                  this.props.history.push('MerchantSidebarNavigation');
                } else {
                  this.props.history.push('Merchant/AddMerchantPayoutDetails');
                }
              } else {
                this.props.history.push('Merchant/AddMerchantBusinessDetails');
              }

            } else {
              this.props.history.push('Merchant/AddMerchantBusinessDetails');
            }
          }
          else
            this.props.history.push("UserSidebarNavigation");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if(getName(MessageEnum.AlertMessage) === message.id){
        const title = message.getData(
          getName(MessageEnum.AlertTitleMessage)
        );

        var AlertBodyMessage = message.getData(
          getName(MessageEnum.AlertBodyMessage)
        );

        if (AlertBodyMessage === "Invalid passcode") {
          this.setState({
            otp: "",
            isClearCalled: true,
            // loginError : AlertBodyMessage
          });
        } else {
          this.setState({
            phoneNumber: "",
            otp: "",
            isClearCalled: true,
            // loginError : AlertBodyMessage
          });
        }
        
        this.props.showNotification(AlertBodyMessage,'error');  
    }
    // Customizable Area End
  }

  // Customizable Area Start

  notifyMessage(msg: string) {
    if (Platform.OS === 'android') {
      ToastAndroid.show(msg, ToastAndroid.SHORT)
    }
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      console.log("Message: ", msg);
      this.send(msg);
    }
  }

  onChangePhoneNumber = (event:any) => {
    this.setState({phoneNumber: event.target.value});
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  //STRAT --here we are validating the phone number and country code and making API call to login user 
  loginAPICall() {
    let mobileNo: any = this.state.phoneNumber;
    const validatePhoneNumberError = this.validatePhoneNumber(mobileNo);

    if (validatePhoneNumberError) {
      this.setState({phoneNumberError: validatePhoneNumberError});
      return;
    }
    const otp = `${this.state.otp}`;
    const validateOtpError = this.validatePasscode(otp);

    if (validateOtpError) {
      this.setState({otpError: validateOtpError});
      return;
    }

    this.setState({ loading: true });

    //here we need to call GET OTP API CALL
    let countryCode: any = this.state.currentCountryCode;
    countryCode = countryCode.indexOf("+") > 0
      ? countryCode.split("+")[1]
      : countryCode

    this.apiType = "sms_account"
    this.attrs = {
      full_phone_number: countryCode + mobileNo,
      password: otp,
      user_type: this.state.value 
    }

    const header = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      type: this.apiType,
      attributes: this.attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return;
  }
  //END --here we are validating the phone number and country code and making API call to login user 

  validatePhoneNumber(phoneNumber: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(phoneNumber)) {
      error = "Phone number " + configJSON.errorBlankField;
    } else if (!this.isValid10DigitPhoneNumber(phoneNumber)) {
      error = configJSON.errorMobileNoNotValid;
    }
    return error;

  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid10DigitPhoneNumber(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 10
    );
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  validatePasscode(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "Passcode " + configJSON.errorBlankField;
    } else if (!this.isValid6DigitPasscode(otp)) {
      error = configJSON.errorPasscodeNotValid;
    }
    return error;

  }

  isValid6DigitPasscode(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 6
    );
  }

  // Customizable Area End
}
