Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "MenuOrdering";
  exports.labelBodyText = "MenuOrdering Body";
  exports.btnExampleTitle = "CLICK ME";
  exports.contentType = "application/json";
  exports.multiformData="multipart/form-data";
  exports.postAPiEndMethod = "POST";
  exports.patchAPiEndMethod = "PATCH"
  exports.deleteAPiEndMethod = "DELETE"
  exports.getAPiEndMethod = "GET"

  
exports.lastOrderEndPoint = "bx_block_menuordering/orders/get_last_order",
exports.orderDetailsEndPoint = "bx_block_ordermanagement/orders/26"
exports.getCurrentOrdersAPIEndPoint = "bx_block_menuordering/orders/get_orders"; //{userid}
exports.getOrderDetailsAPIEndPoint = "bx_block_ordermanagement/orders/orderId"; //{userid}
exports.getOTPAPiEndPoint = "account_block/accounts/send_otps";
exports.verifyOTPAPiEndPoint = "account_block/accounts/sms_confirmations";
exports.getMFADetailsAPiEndPoint = "bx_block_ordermanagement/orders/";
exports.getMerchantPrintPrice = "/account_block/accounts/merchants/merchant_get";
exports.getinvoicesAPIEndPoint ="/bx_block_ordermanagement/monthly_invoices"
exports.postInvoicesApiEndPoint ="/bx_block_ordermanagement/monthly_invoice_transactions/create_monthly_transaction"
exports.payment_via_upi_for_monthly_transaction ="/bx_block_ordermanagement/monthly_invoice_transactions/payment_via_upi_for_monthly_transaction"


  //API CONSTANTS


//Labels
exports.navBarName="David Wong";
exports.pageTitle="Home";
exports.POrderNumber="Order #23432";
exports.pDocs="12 Docs";
exports.userNameWith="with Sourabh Mishra";
exports.orderCompleteText="Order Complete";
exports.ShoporderAmount="Rs. 500";
exports.orderTime="12/12/2020 12:20 PM";
exports.recentOrders="Recent Orders";
exports.seeAll="See All";

//verify otp
exports.labelVerifyOtp = "Verify OTP";
exports.labelOtp = "OTP";
exports.oneLabelVerifyOtpMessage = "An OTP is sent to customer's registered mobile number ";
exports.twoLabelVerifyOtpMessage = ", Ask him to provide OTP for printing the document";
exports.submitBtntext = "Submit";
exports.resendBtntext = "Resend";
exports.errorBlankField = "can't be blank";
exports.errorOTPNotValid = "Enter valid 4 digit OTP";
//verify otp
// Customizable Area End