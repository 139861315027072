//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
// Customizable Area Start

// Customizable Area End

export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  loading: boolean;
  creditScores: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreditCashBalanceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCreditStoredCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      loading: false,
      creditScores: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {
      let AlertBodyMessageCreditCash = message.getData(
        getName(MessageEnum.AlertBodyMessageCreditCash)
      );
      this.props.showNotification(AlertBodyMessageCreditCash, "error");
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdCreditCash = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJsonCreditCash = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallIdCreditCash === this.apigetCreditStoredCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJsonCreditCash && responseJsonCreditCash.data) {
          this.setState({
            creditScores:
              responseJsonCreditCash.data[0].attributes.credit_amount
          });
        } else {
          this.props.showNotification(responseJsonCreditCash.errors, "error");
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentWillUnmount() {
    clearInterval(this.handleTimeInterval);
  }

  getCreditScores() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetCreditStoredCallId = requestMessage.messageId;

    let getCreditScoresAPIEndPoint = configJSON.getCreditScoresAPIEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getCreditScoresAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  // Customizable Area End
}
