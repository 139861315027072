// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import withRouter from "react-router/withRouter";
import ReferAndEarnController, {
    Props,
    configJSON
  } from "./ReferAndEarnController.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { arrowRightIcon , referCoupan } from "./assets";

// Customizable Area Start
// Customizable Area End
class ReferAndEarn extends ReferAndEarnController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  createReferralCode(){
    if(this.state.reference_code === null){
        this.getReferralCode()
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          token: loginData.token,
          reference_code : loginData.referral_code
        });
      }
    });
    this.createReferralCode();
  }
  copyReferralCode = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          this.state.reference_code === null ? this.state.referral_code : this.state.reference_code
        );
        this.setState({ isCopied: true });
        setTimeout(() => {
          this.setState({ isCopied: false });
        }, 500);
      } 
      catch (err) {
        console.error('Unable to copy to clipboard: ', err);
      }
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
        <Grid container className={classes?.offerSidebarBgReferAndEarn}>
            <Grid item sm={8} xs={12} className={classes?.leftSidebarReferAndEarn}>
                <Grid container alignItems={'center'} className={classes?.parentContainerReferAndEarn}>
                    <Grid item xs={3}>
                        <Box className={classes?.backIconReferAndEarn}>
                        <img
                            src={arrowRightIcon}
                            data-test-id="navigationHandle"
                            className={classes?.editProfileArrowIconReferAndEarn}
                            onClick={()=>this.props.history?.push("/UserSettings/Settings/OfferAndDiscounts")}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <p className={classes?.labelSettingReferAndEarn}>{configJSON.referAndEarn}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <img 
                                src={referCoupan}
                                className={classes?.referImgSecReferAndEarn}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={classes?.parentWorksContainerReferAndEarn}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h4" className={classes?.worksHeadingReferAndEarn}>How it works?</Typography>
                            <Box className={classes?.workListContentReferAndEarn}>
                                <Box className={classes?.workWrapperReferandEarn}>
                                    <span className={classes?.workStepsReferAndEarn}></span> 
                                    <Typography variant="h4" className={classes?.worksInHeadingReferAndEarn}>You get <span> ₹51 cashback</span> when a friend places their first print order.</Typography>
                                </Box>
                                <Box className={classes?.workWrapperReferandEarn}>
                                    <span className={classes?.workStepsReferAndEarn}></span> 
                                    <Typography variant="h4" className={classes?.worksInHeadingReferAndEarn}>Friends get flat <span> ₹101 off & free delivery</span> on their first print order.</Typography>
                                </Box>
                                <Box className={classes?.workWrapperReferandEarn}>
                                    <span className={classes?.workStepsReferAndEarn}></span> 
                                    <Typography variant="h4" className={classes?.worksInHeadingReferAndEarn}>You get ₹2500 cashback for the first fifty successful referals.</Typography>
                                </Box>
                            </Box>
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} className={classes?.referalCodeBlockReferAndEarn}>
                                <Typography variant="h5">Referal Id:</Typography>
                                <Typography className={classes?.referalCodeIdReferAndEarn}>{this.state.reference_code === null ? this.state.referral_code : this.state.reference_code }</Typography>
                                <Typography variant="h5" className={classes?.referalCopyTextReferAndEarn} data-test-id="copyReferal" onClick={this.copyReferralCode}>Copy</Typography>
                                {this.state.isCopied && <span>Copied!</span>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className={classes?.referNowBtnReferAndEarn}>
                    <Button variant="contained">Refer Now</Button>
                </Box>
            </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    offerSidebarBgReferAndEarn: {
        backgroundColor: "#f1f1f1",
        height: "100%",
    },
    leftSidebarReferAndEarn: {
        margin: '0 auto',
    },
    parentContainerReferAndEarn: {
        padding: "16px",
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    sofiaFont: {
        fontFamily: APP_FONTS.SofiaProRegular
    },
    backIconReferAndEarn: {
        height: '48px',
        width: '48px',
        backgroundColor: '#32795f',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        cursor: 'pointer',
        transform: 'rotate(180deg)',
    },
    editProfileArrowIconReferAndEarn: {
        width: "21px",
        height: "21px",
        margin: '0 15px',
    },
    labelSettingReferAndEarn: {
        fontFamily: APP_FONTS.SofiaProRegular,
        fontSize: "20px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.8,
        letterSpacing: "normal",
        textTransform: 'uppercase',
        color: "#1e1f20",
        marginBottom: 0,
        textAlign: 'center',
    },
    referImgSecReferAndEarn: {
        width: '100%',
    },
    parentWorksContainerReferAndEarn: {
        borderRadius: '15px',
        width: '94%',
        backgroundColor: '#fff',
        margin: '0 auto 20px',
        padding: 20,
        minHeight: 300,
        textAlign: 'center',
    },
    worksHeadingReferAndEarn: {
        fontWeight: 700,
        fontFamily: APP_FONTS.SofiaProRegular,
        marginBottom: 15,
    },
    worksInHeadingReferAndEarn: {
        fontWeight: 400,
        fontFamily: APP_FONTS.SofiaProRegular,
        textAlign: 'left',
        '& span': {
            fontWeight: 700
        }
    },
    workListContentReferAndEarn: {
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        position: 'relative',
        '& h4': {
            '& span': {
                fontWeight: 700,
            }
        }
    },
    workStepsReferAndEarn: {
        position: 'relative',
        borderRadius: '50%',
        backgroundColor: '#32795f',
        height: 16,
        width: 16,
        minWidth: 16,
        display: 'inline-flex',
        outline: '2px solid #32795f',
        border: '3px solid #FFF',
        margin: '5px 10px 5px 0',
        '&::before': {
            content: '""',
            display: 'block',
            borderLeft: '2px dashed #32795f',
            height: '100%',
            position: 'absolute',
            top: 15,
            left: 4,
            zIndex: 0,
            paddingBottom: 85,
        },
    },
    workWrapperReferandEarn: {
        display: 'flex',
        marginBottom: 50,
        '&:last-child': {
            '& span': {
                '&::before': {
                    display: 'none'
                },
            },
        },
    },
    referalCodeBlockReferAndEarn: {
        padding: 20,
        borderRadius: 12,
        gap: 20,
        backgroundColor: '#a9fde5',
        '& h5': {
            fontFamily: APP_FONTS.SofiaProRegular,
            fontSize: 18,
        }
    },
    referalCopyTextReferAndEarn: {
        color: '#32795f',
        textTransform: 'uppercase',
        cursor: 'pointer'
    },
    referalCodeIdReferAndEarn: {
        backgroundColor: '#fff',
        border: '1px dashed #32795f',
        padding: '7px 12px',
        fontSize: 20,
        width: 'fit-content',
        textAlign: 'left',
        fontFamily: APP_FONTS.SofiaProRegular,
    },
    referNowBtnReferAndEarn: {
        width: '94%',
        margin: '0 auto 20px',
        '& button': {
            backgroundColor: '#32795f !important',
            borderRadius: 12,
            color: '#FFF',
            padding: 20,
            fontSize: 18,
            fontWeight: 700,
            width: '100%',
            fontFamily: APP_FONTS.SofiaProRegular,
        }
    }
  });
  // Customizable Area End

  const ReferAndEarnBlockWithLoader = withLoader(ReferAndEarn)
  const ReferAndEarnBlockWithToast = withToast(ReferAndEarnBlockWithLoader)
  export {ReferAndEarn}
  export default withRouter(withStyles(styles, { withTheme: true })(ReferAndEarnBlockWithToast));