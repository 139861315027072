// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  phoneIcon,
  arrowRightIcon,
  loginPasscodeIcon,
  editPhoneColorIcon,
  editProfileColorIcon,
  loginPasscodeColorIcon,
  editProfileIcon,
  arrowRightBlack,
} from "./assets";
import { APP_FONTS } from "../../../components/src/AppFonts";
import UserSettingsController, {
  Props,
  configJSON,
} from "./UserSettingsController.Web";
import withRouter from "react-router/withRouter";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
// Customizable Area Start
// Customizable Area End
class OfferAndBenefits extends UserSettingsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start

  isActiveLink = (pathName) => {
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes(pathName)) {
      return true;
    }
    if (pathName == this.state.selectedPath) {
      return true;
    }
    return false;
  };

  navigationLinkUser =
    [
      {
        name: "Refer and Earn",
        path: "/UserSettings/Settings/OfferAndDiscounts/ReferAndEarn",
        currentPath: "ReferAndEarn",
        img: editProfileColorIcon,
        unImg: editProfileIcon,
      },
      {
        name: "Credit Store",
        path: "/UserSettings/Settings/OfferAndDiscounts/CreditStore",
        currentPath: "CreditStore",
        img: editPhoneColorIcon,
        unImg: phoneIcon,
      },
      {
        name: "Coupons",
        path: "/UserSettings/Settings/OfferAndDiscounts/Coupons",
        currentPath: "Coupons",
        img: loginPasscodeColorIcon,
        unImg: loginPasscodeIcon,
      },
      {
        name: "Payment",
        path: "/UserSettings/Settings/OfferAndDiscounts/Payment",
        currentPath: "Coupons",
        img: loginPasscodeColorIcon,
        unImg: loginPasscodeIcon,
      },
    ]

  navigateTo = (pathName) => {
      this.props.history.push(pathName);
    }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Grid container className={classes.offerSidebarBg}>
        <Grid item sm={8} xs={12} className={classes.leftSidebar}>
          <Grid container alignItems={'center'} className={classes.parentContainer}>
            <Grid item xs={3}>
                <Box className={classes.backIcon}>
                <img
                      src={arrowRightIcon}
                      className={classes.editProfileArrowIcon}
                      onClick={()=>this.props.history.push("/UserSettings/Settings")}
                />
                </Box>
            </Grid>
            <Grid item xs={6} >
              <p className={classes.labelSetting}>{configJSON.offerAndBenefits}</p>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent={"space-between"}
          >
            {
              (this.state.account_type === 'guest_account' ? this.navigationLinkGuest : this.navigationLinkUser).map((navItem) => {
                return this.isActiveLink(navItem.currentPath) ?
                  <Grid key={navItem.name} item xs={12} className={classes.editProfileColorButton} onClick={() => {
                    this.navigateTo(navItem.path)
                    this.setState({ selectedPath: navItem.currentPath })

                  }}>
                    <span
                        to={navItem.path}
                        className={`${classes.sofiaFont}  ${classes.editProfileColorButtonTxt}`}
                        >
                      {navItem.name}
                    </span>
                    <img
                      src={arrowRightIcon}
                      className={classes.editProfileArrowIcon}
                    /></Grid> :
                  <Grid key={navItem.name} item xs={12} className={classes.editProfileButton} onClick={() => {
                    this.navigateTo(navItem.path)
                    this.setState({ selectedPath: navItem.currentPath })

                  }}>
                    <span
                      to={navItem.path}
                      className={`${classes.sofiaFont}  ${classes.editProfileButtonTxt}`}
                    >
                      {navItem.name}
                    </span>
                    <img
                      src={arrowRightBlack}
                      className={classes.editProfileArrowIcon}
                    />
                    </Grid>
              })
            }
          </Grid>
        </Grid>
      </Grid >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const styles = (theme: Theme) =>
  createStyles({
    leftSidebar: {
      // backgroundColor: "#f7f7f7",
      margin: '0 auto',
    },
    offerSidebarBg: {
      backgroundColor: "#f1f1f1",
      height: "100%",
    },
    sofiaFont: {
      fontFamily: APP_FONTS.SofiaProRegular
    },
    printIcon: {
      width: "50px",
    },
    logoutIcon: {
      width: "48px",
      height: "48px",
      margin: "180px 11px 0 7px",
      objectFit: "contain",
    },
    labelSetting: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "20px",
      fontWeight: 700,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      textTransform: 'uppercase',
      color: "#1e1f20",
      marginBottom: 0,
      textAlign: 'center',
    },
    settingAvatar: {
      width: "48px",
      height: "48px",
      borderRadius: "16px",
      float: "right",
    },
    parentContainer: {
      padding: "16px",
      backgroundColor: '#fff',
      marginBottom: 20,
    },
    editProfileColorButton: {
      width: "90%",
      margin: "3% auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      padding: "17px ",
      borderRadius: "12px",
      backgroundColor: "#32795f",
    },
    editProfileButton: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      cursor: "pointer",
      padding: "22px 15px",
      backgroundColor: "#fff",
      borderBottom: '1px solid #cccccc80'
    },
    editProfileButtonIcon: {
      width: "40px",
      height: "40px",
      position: 'relative'
    },
    editProfileColorButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "70%",
      marginLeft: "15px",
      textAlign: "left",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#ffffff",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "100%",
      paddingLeft: "15px",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#1e1f20",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileArrowIcon: {
      width: "21px",
      height: "21px",
      margin: '0 15px',
    },
    backIcon: {
        height: '48px',
        width: '48px',
        backgroundColor: '#32795f',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        cursor: 'pointer',
        transform: 'rotate(180deg)',
    }
  });
// Customizable Area End
const OfferAndBenefitsWithConfirmBox = withConfirmBox(OfferAndBenefits);
const OfferAndBenefitsWithLoader = withLoader(OfferAndBenefitsWithConfirmBox)
const OfferAndBenefitsWithConfirmBoxWithToast = withToast(OfferAndBenefitsWithLoader)

export default withRouter(
  withStyles(styles, { withTheme: true })(OfferAndBenefitsWithConfirmBoxWithToast)
);