// @ts-nocheck
import React,{ createRef } from "react";
// Customizable Area Start
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End
import Header from "./Header.Web";
import LandingPageController, {
  Props
} from "./LandingPageController.Web";
import './Header.css';
import './LandingPage.css';
import {
  leftHandImg,
  appleStore,
  googlePlayStore,
  backDots,
  check1,
  check2,
  thumbImg,
  dataPrivacy,
  dataEncryption,
  multiFactor,
  documentSecurity,
  secureDownload,
  documentDelivery,
  landingPageVideo,
  testimonialImg,
  workImg,
  publicQueueImg,
  privateQueueImg,
  hostedQueueImg,
  publicQueueSeletedImg,
  privateQueueSelectedImg,
  hostedQueueSelectedImg,
  footerImg,
  gtxImg,
  glxImg,
  wlxImg,
  envlpImg,
  twitterImg,
  gtImg,
  pImg,
  serviceProviderImg,
  serviceProvideSelectedImg,
  userUnSelectedImg,
  testimonialImg1,
  testimonialImg2,
  combinedApplink
} from './assets'

class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollToDownloadDiv = createRef();
    this.scrollToGetInTouchDiv = createRef();
    this.scrollToHowItWorkDiv = createRef();
    this.scrollToKnowMoreDiv = createRef();
    this.scrollToServiceKnowMoreDiv = createRef();

    // Customizable Area End
  }
  changeMenu=()=>{
    this.setState({mobilemenu:!this.state.mobilemenu})
    const mobileMenu=document.getElementById("navMobileDropdown")
    mobileMenu.classList.toggle("navMobileHeight")
  }

   // Customizable Area Start
  // Customizable Area End
   async componentDidMount() {

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.serviceState === "service-page") {
      this.scrollToDownloadDiv.current.scrollIntoView({ behavior: 'smooth' });
     } 

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.serviceDetailState === "service-detail") {
      this.scrollToDownloadDiv.current.scrollIntoView({ behavior: 'smooth' });
     }

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.downloadState === "download-state") {
      this.scrollToDownloadDiv.current.scrollIntoView({ behavior: 'smooth' });
     } 


     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.serviceKnowMoreState === "service-know-more") {
      this.setState({tabIndex:1}, () => {this.scrollToServiceKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.serviceDetailKnowMoreState === "service-detail-know-more") {
      this.setState({tabIndex:1}, () => {this.scrollToServiceKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.publicQueueState === "header-puclicQueue") {
      this.setState({tabIndex:0}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.privateQueueState === "header-privateQueue") {
      this.setState({tabIndex:1}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.hostedQueueState === "header-hostedQueue") {
      this.setState({tabIndex:2}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }
     // Customizable Area Start
     this.getFaqsQuestions();
     this.getLandingPageVideo();
      // Customizable Area End
  }

   next = () => {
    if (this.state.currentSlide===this.state.reviews.length - 1)
    {
      this.setState((state) => ({
        currentSlide: 0,
      }));
    }
    else
    {
      this.setState((state) => ({
        currentSlide: state.currentSlide + 1,
      }));
    }

   };

   prev = () => {
     if (this.state.currentSlide===0)
     {
       this.setState((state) => ({
         currentSlide: state.reviews.length - 1,
       }));
     }
     else
     {
       this.setState((state) => ({
         currentSlide: state.currentSlide - 1,
       }));
     }
   };

   changeAutoPlay = () => {
     this.setState((state) => ({
       autoPlay: !state.autoPlay,
     }));
   };

   updateCurrentSlide = (index) => {
     const { currentSlide } = this.state;

     if (currentSlide !== index) {
       this.setState({
         currentSlide: index,
       });
     }
   };
   updateBannerSlideOnClick=(value)=>
   {
     this.setState((state) => ({
       banner: value
     }));
   }
   navigateTo = (pathName) => {
       this.props.history.push(pathName);
   }
   radioHandler = (userTypeStatus) => {
    this.setState({ userTypeStatus });
  };

  navigationMenuHandler = (tabIndex) => {
    this.setState({tabIndex}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
  }

  navigationToDownload = () => {
    this.scrollToDownloadDiv.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { classes } = this.props;
    const { userTypeStatus } = this.state;
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.parentCont}
      >
       <Header navigationMenuHandler={(index) => this.navigationMenuHandler(index)} navigationToDownload={() => this.navigationToDownload()}/>
        <Grid item xs={12} className={"navBarTopMargin"}/>
        <Grid item xs={12}>
        <div className="jumbotron text-center">
          <div className="col-lg-6">
          <div className="Group_12438">
              <div className="Get_Your_Documents_printed_Sec">
                <span>Get Your Documents</span><br></br>
                <span className="header-line">Printed Securely</span>
              </div>
              <div className="A_SaaS_based_encrypted_printin">
                <span className="alx">A SaaS based encrypted printing service that allows you to print your documents securely from any device to any printer around you.</span>
              </div>
              <p className="get-btn">
              <a onClick={() => {
                this.scrollToDownloadDiv.current.scrollIntoView({ behavior: 'smooth' });
               }}>
               Let’s get started!</a>
             </p>
            </div>
        </div>
        </div>
        </Grid>
        <div className="container vid-section">
          <div className="row">
            <div className="col-lg-6">
              <div className="video-left">
                <h2>Agile, Secure & Smart</h2>
                <h3>Printing Services</h3>
                <p>Whether you are a Student or a Professional present/located at your home, office or a public place printing documents through PrintSEC is completely secure. You have full control over your documents even after firing print and your data is completely private to you.</p>
                <p className="extra-line">In case you are a printing service provider or a shop owner, onboarding yourself to PrintSEC application platform will allow you to attract an immense amount of customers and also improve your productivity by multiple folds.</p>
                <p className="get-btn vid-btn">
              <a onClick={() => {
                this.scrollToDownloadDiv.current.scrollIntoView({ behavior: 'smooth' });
               }}>
              Download The App</a>
            </p>
            <span className="lnk" onClick={() => {
              this.scrollToHowItWorkDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>How It Works?</span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video vid-ld">
              {this.state.landingPageVideo &&
                  <video controls autoPlay={true} muted className={classes.bannerRightImg}   >
                    <source type="video/mp4"src={this.state.landingPageVideo} ></source>
                  </video>
                }
              </div>
              <div className="dot"><img src={backDots} /></div>
            </div>
          </div>
        </div>
        <div className="container" ref={this.scrollToDownloadDiv}>
        <div className="row">
          <div className="col-lg-5">
            <div className="left-gle">
              <img src={leftHandImg} />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-gle">
              <h2>Download <font>the App!</font></h2>
              <p>PrintSEC application is available across all three platforms iOS, Android and Web. Scan the QR code to download the application and enjoy the hassle free printing on the go.</p>
              <ul className="gle-btn">
                <li><a href="https://apps.apple.com/in/app/id1545304010" target="_blank"><img src={appleStore} /></a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.SecurePrinting" target="_blank"><img src={googlePlayStore} /></a></li>
                {/* <li class="web-st"><img src={webStore} /></li> */}
              </ul>
              <p className="scan-txt">Scan to Download</p>
              <ul className="bar-code">
                <li><img src={combinedApplink} className={classes.qrCodeImg}/></li>
                {/* <li><img src={appQrCode} /><br></br><span className="nme">App store</span></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container grid-align">
       <div className="row">
        <div className="print-section" ref={this.scrollToHowItWorkDiv}>
        <h2>Print In <font>3 Easy Steps</font></h2>
        <ul className="print-list">
          <li><div className="custom-checkbox">
          <input className="bn-green-checkbox" name="a-checkbox" aria-checked="false" tabIndex="1" checked={userTypeStatus === 1} onClick={(e) => this.radioHandler(1)}  type="radio" />
          <label htmlFor="a-checkbox"></label>
          </div>{userTypeStatus === 1 ? <img src={check1} /> : <img src={userUnSelectedImg} /> }<br></br><span className={userTypeStatus === 1 ? 'prt1' : 'prt'}>I am a user</span>
          </li>
          <li><div className="custom-checkbox">
          <input className="bn-green-checkbox" name="a-checkbox1"  aria-checked="false" tabIndex="2" checked={userTypeStatus === 2} onClick={(e) => this.radioHandler(2)} type="radio" />
          <label htmlFor="a-checkbox"></label>
         </div>
         {userTypeStatus === 2 ? <img src={serviceProvideSelectedImg} /> : <img src={check2} />}<br></br><span className={userTypeStatus === 2 ? 'prt1' : 'prt'}>I am a service provider</span>
         </li>
        </ul>
        {
          userTypeStatus === 1 &&
        <div className="container grid-align">
          <div className="row">
            <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 1: Upload your documents</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 2: Make payment</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 3: Get your document printed</h2>  
              </div>
            </div>
          </div>
        </div>
        }
        {
          userTypeStatus === 2 &&
        <div className="container grid-align">
          <div className="row">
            <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 1: Onboard to PrintSEC platform</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 2: Print the document</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 3: Get Payment</h2>
              </div>
            </div>
          </div>
        </div>
        }
        <div className="container grid-align">
          <div className="row">
            { userTypeStatus === 1 &&
            <div>
            <div className="img-cntr">
              <img src={thumbImg} />
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Visit <a href="https://www.printsec.app"><font className="grn-txt" >www.printsec.app</font></a> or download our app on the app store (iOS/Android)</li>
                  <li>Sign in/log in with your phone number and PIN</li>
                  <li>Upload the documents and images to print OR scan them using our scanner!</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Complete your payment with any of the available payment options.</li>
                  <li>Get an encryption key that will be scanned at the printer shop.</li>
                  <li>Your document is completely private to you.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Select the printer nearest to you from the list or visit the shop of your choice.</li>
                  <li>Follow the app and it will direct you to your print shop.</li>
                  <li>Scan the encryption key at the shop.</li>
                  <li>Take your printout, check quality!</li>
                  <li>Acknowledge job completion in the application.</li>
                </ul>
              </div>
            </div>
            </div>
            }
            { userTypeStatus === 2 &&
            <div>
            <div className="img-cntr">
              <img src={serviceProviderImg} />
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Visit <a href="https://www.printsec.app"><font className="grn-txt" >www.printsec.app</font></a> or download our app store (iOS/Android)</li>
                  <li>Sign in/log in with your phone number and PIN instead of username</li>
                  <li>Update your payment profile, i.e., enter the payment methods you’ll accept.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Scan the encryption key for the authentication of the customer’s documents.</li>
                  <li>Select the printer on which print job has to be sent.</li>
                  <li>Handover the printout to the customer.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Receive the payment to the registered AC/UPI updated in your payment profile.</li>
                  {/* <li>Scan the encryption key at the printer’s shop.</li>
                  <li>Take your printout, check quality!
                  Acknowledge the job completion in application.</li> */}
                </ul>
              </div>
            </div>
            </div>
            }

            <div className="container grid-align">
              <div className="row">
                <div className="get">
                  <div className="col-lg-7">
                    <div className="gt-txt">
                      <h2>Are You An Enterprise? Get Started.</h2>
                      <p>PrintSEC application is available for enterprises under our offering private queue and hosted queue.</p>
                    </div>
                  </div>
                  <div className="col-lg-5 orx">
                    <div className="gtm-txt">
                      <a onClick={() => {
                      this.setState({tabIndex:1}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
                       }}>Know More</a>
                      <span className="orz">Or</span>
                      <a onClick={() => {
                      this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                       }}>
                      Get In Touch</a>
                      </div>
                      </div>
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bx" styles="display: inline-block;">
        <div className="row">
          <div className="overly">
            <div className="inside-content">
              <h2>Why <font>Printsec?</font></h2>
              <p>PrintSEC is a startup organisation founded with the purpose to support digitalization in the non IT industry that has potential to grow through digital transformation.</p>
            </div>
          </div>
          <div className="container">
            <div className="row lnx">
            <div className="col-lg-4 doorst cd-pt">
              <div className="card">
              <div className="circle"></div> 
                <img src={documentDelivery} />
                <h4>Document delivered at your doorstep!</h4>
                <p>Order your printed documents delivery at your doorstep.</p>
              </div>
            </div>
            <div className="col-lg-4 bx-align cd-pt">
              <div className="card">
              <div className="circle"></div> 
                <img src={dataEncryption}/>
                <h4>Document Encryption</h4>
                <p>All the documents uploaded on PrintSEC are encrypted. Only the data owner can decrypt the data. </p>
              </div>
            </div>
            <div className="col-lg-4 dt-pv">
              <div className="card data-pvt">
              <div className="circle"></div> 
                <img src={dataPrivacy} />
                <h4>Data Privacy</h4>
                <p>We ensure your personal and sensitive documents remain 100% private to you, Even we at PrintSEC can't see your data.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 cd-pt">
              <div className="card">
              <div className="circle"></div> 
                <img src={documentSecurity} />
                <h4>Document Security</h4>
                <p>All the printable documents sent to the queue are authenticated with an encryption key.</p>
              </div>
            </div>
            <div className="col-lg-4 bx-align1">
              <div className="card data-pvt">
              <div className="circle"></div> 
                <img src={secureDownload} />
                <h4>Secure Downloads</h4>
                <p>Secure download allows users to download their documents by entering OTP. </p>
              </div>
            </div>
            <div className="col-lg-4 cd-pt mult">
              <div className="card data-pvt1">
              <div className="circle"></div> 
                <img src={multiFactor} />
                <h4>Multi-Factor Authentication</h4>
                <p>Only successful authentication can pull a job from the queue.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="printing-services">
            <h2>Secure <font>Printing Services</font></h2>
            <p>PrintSEC is a SaaS-based encrypted printing service that offers a secure and simple solution, It does not require any additional hardware and software infrastructure. All the print jobs are queued up in an encrypted queue with an encryption key. The end-user uses this key to get the document printed.</p>
            <p>Services are available in following three distinct service offerings</p>
          </div>
        </div>
       </div>  
       <div className="container tabs-view-fix" ref={this.scrollToKnowMoreDiv}>
        <div className="row" ref={this.scrollToServiceKnowMoreDiv} id="tab-know-more">
        <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({tabIndex:index})}>
        <div className="col-lg-4">
        <TabList>
          <Tab> 
            {
            this.state.tabIndex === 0 ?  <img src={publicQueueSeletedImg} /> :
            <img src={publicQueueImg} />
            }
            <p>Public Queue</p>
          </Tab>
          <Tab>
            {
             this.state.tabIndex === 1 ? <img src={privateQueueSelectedImg} /> :
            <img src={privateQueueImg} />
            }
            <p>Private Queue</p>
          </Tab>
          <Tab>
            { this.state.tabIndex === 2 ? <img src={hostedQueueSelectedImg} /> :
            <img src={hostedQueueImg} />
            }
            <p>Hosted Queue</p>
          </Tab>
        </TabList>
        </div>
        <div className="col-lg-7">
        <TabPanel>
          <div className="panel-content">
            <h2>
            <b>Public Queue</b>
            </h2>
            <p>This service is available via web and mobile applications for iOS and Android. It allows both end-users and service providers a convenient and secure way of printing documents on the go.</p>
            <p>The platform not only provides data privacy and security to end-users but also improves the agility of all registered service providers who are visible to end-users on the Printsec application. This way printing documents for service providers is just a click away and also improves their productivity by multiple folds.</p>
            {/* <h3><b className="wt">Pricing</b> - <b className="wt1">Monthly fixed + Onboarding charges</b> </h3>
            <h3><b className="wt">Tenancy</b> - <b className="wt1">Dedicated</b> </h3>
            <h3><b className="wt">Hosting</b> - <b className="wt1">AWS</b> </h3> */}
            <div className="gtm-txt gmn">
              <a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a></div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <h2>
            <b>Private Queue</b>
            </h2>
            <p>This is a custom dedicated private queue offered in a SaaS model allows Small, medium and large enterprises to have full control on documents being sent for printing, reduces TCO by reducing wastage of accesories, Improves organizations agility by allowing their users to consume service through Web and mobile application. We’ve rolled out mobile applications for both the Apple store and Google Play store.</p>
            <h3><b className="wt">Pricing</b> - <b className="wt1">Monthly fixed + Onboarding charges</b> </h3>
            <h3><b className="wt">Tenancy</b> - <b className="wt1">Dedicated</b> </h3>
            <h3><b className="wt">Hosting</b> - <b className="wt1">AWS</b> </h3>
            <div className="gtm-txt gmn">
              <a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a></div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <h2>
            <b>Hosted Queue</b>
            </h2>
            <p>This is a custom dedicated hosted private queue service. It can be made available in COLO/Client’s DC or Private/Public cloud specially tailored for the enterprises needs PrintSEC application deployed in their environment for various compliance requirements. Web, iOS and Android versions of the application are available for hosted queue as well.
            Specially Public and BFSI enterprises who have strict compliance policy and less control over the data being sent out to from system to printers can benefit from our hosted queue which not only strengthen your data security posture but also gives you transparency, control and improves your agility by allowing your employees, partners and users print on the go.</p>
            <h3><b className="wt">Pricing</b> - <b className="wt1">Monthly fixed + Onboarding charges</b> </h3>
            <h3><b className="wt">Tenancy</b> - <b className="wt1">Dedicated</b> </h3>
            <h3><b className="wt">Hosting</b> - <b className="wt1">COLO/Customer DC or Public/Private cloud.</b> </h3>
            <div className="gtm-txt gmn">
              <a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a></div>
          </div>
        </TabPanel>
        </div>
      </Tabs>
        </div>
        </div>
       <div className="container-fluid bx1">
          <h2>Testimonials </h2>
          <p className="tst-qt">See what our clients speak about their experiences!</p>
          {/* <p className="arrow"><span className="arrow-left"></span> 
          <span className="arrow-right"></span></p> */}
          <div className="row">
            <div styles="width:75%;" className="container txts">
            <div className="col-lg-6">
              <div className="cardx">
              <p className="qte"></p>
                <p>Having a business idea I was quite panicky to kick start at the earliest before it comes to any other mind. As a non IT professional I was completely puzzled how and where to start. Someone referred me to these guys, The Solution Architect assigned in my case was very skilled having working experience of more than 18 yrs. After a few meetings I was pretty convinced and decided to open up here with my brain child. Surprisingly the solution architect and his team were excellent in perceiving my all ideas of business and within a week they came up with a complete package of solution with a timeline. It was so professionally designed that I couldn't find any drawbacks to refuse. My overall experience was total peace of mind. One stop single window solutions.</p>
                <p className="user"><span><img src={testimonialImg1} className="testiImg" /></span><span className="ct">Akhtarul Iman.</span><span className="des1">Founder and CEO.</span><span className="des1">KARLOSi TECHNOLOGIES PVT. LTD</span></p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cardx">
               <p className="qte"></p>
                <p>
                During my final year of Master in Public Health I was working on some confidential project that I didn’t want to share with anyone considering the fact that my idea and content may be stolen. Through my close friends I discovered this application called PrintSEC, Which is a secure printing application that allows me to print my confidential document from my mobile device without sharing my files with the Shop owner.
                I would recommend this application to all students and professionals who value their documents.
                </p>
                <p className="user"><span><img src={testimonialImg2} className="testiImg" /></span><span className="ct">Aamir Mohsin</span><span className="des1">Healthcare Data Analyst.</span></p></div>
            </div>
          </div>
          </div>
        </div>
        <div className="fq-t">
        <div className="mask"></div>
        <div className="container faq-align">
        <h2 className="fq">FAQ's</h2>
        <div className="row">
        {this.state.landingPageFAQs.map((item) => (
                <Accordion key={item.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className={classes.heading}
                    >
                      <div className="filx">
                      </div>
                      <font>Q.</font>
                      {item.attributes.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Typography className="txt">
                      {item.attributes.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="dot1"><img src={backDots} /></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="work">
                <img src={workImg} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="left-wrk">
                <h2>Let's <font>work together!</font></h2>
                <p>Improve your data security and agility, Reduce your TCO! Transform your business now, let’s work together and revolutionize printing by using PrintSEC. Get in touch with us today.</p>
                <div className="gtm1-txt">
                  <a href="#">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container testimonial-align">
          <div className="row">
            <div className="testimonial-inner-content">
              <h2>Message from <font>CEO</font></h2>
              <p>PrintSEC has a vision to simplify and digitalize everything that has an impact on human life and make technology simple to use for everyone including those who do not understand it.</p>
              <h3>Uzma Nizam</h3>
              <p className="des">CEO & MD</p>
            </div>
          </div>
        </div>
        <Grid>
      <div className="container-fluid ft">
      <div className="row">
        <div className="footer-content">
          <div className="col-lg-4">
            <div className="logo-footer">
              <img src={footerImg} />
              <p>PrintSEC has immense potential to digitalize your business and move it forward through a digital transformation.</p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-menu">
              <h3>QUICK LINKS</h3>
              <ul className="list">
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Services")} >Services</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/About-us")} >About us</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Contact-us")}>Contact us</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2" ref={this.scrollToGetInTouchDiv}>
            <div className="footer-menu">
              <h3>GET IN TOUCH
              </h3>
              <p className="salesContent">Sales Specialists at PrintSEC will help you start your digital journey. Reach out to us through:</p>
              <ul className="list contx">
              <a href="mailto:connect@printsec.app"><li><span><i className="fa fa-envelope" aria-hidden="true"></i></span>connect@printsec.app</li></a>
              <a href="tel:+91-9319480924"><li><span><i className="fa fa-phone" aria-hidden="true"></i></span>+91-9319480924</li></a>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-menu btz">
              <h3>DOWNLOAD THE APP</h3>
              <ul className="list">
              <li><a href="https://apps.apple.com/in/app/id1545304010" target="_blank"><img src={gtxImg} /></a></li>
              <li><a href="https://play.google.com/store/apps/details?id=com.SecurePrinting" target="_blank"><img src={glxImg} /></a></li>
                {/* <li><img src={wlxImg} /></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row social-icon">
        <div className="social">
          <a href="mailto:connect@printsec.app"><img src={envlpImg} /></a>
          <a href="https://twitter.com/PrintsecD" target="_blank"><img src={twitterImg} /></a>
          <a href="https://in.linkedin.com/company/printsec-digital" target="_blank"><img src={gtImg} /></a>
          <a href="https://www.instagram.com/printsecdigital/" target="_blank"><img src={pImg} /></a>
        </div>
      </div>
    </div>
    <div className="container-fluid bt">
      <div className="row">
        <div className="col-lg-5">
          <div className="bottom-ft">
            <p>© 2021 PrintSEC Digital</p>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="bottom-ft">
        <ul className="lst">
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Enduser-licence")} >End user license agreement</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Privacy-policy")}>Privacy policy</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Terms-and-conditions")}>Term and Conditions</li>
        </ul>
        </div>
        </div>
      </div>
    </div>
    </Grid>
      </Grid>

      //Merge Engine End DefaultContainer
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  
  navBar:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    boxShadow:"0 2px 7px 0 rgba(147, 147, 147, 0.5)"
  },
  navMiddle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-evenly"
  },
  navLeft:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  navRight:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  footerCont:{
    backgroundColor:APP_COLORS.app_theme_green_color,
    borderRadius:32,
    boxShadow: "0 -1px 6px 0 rgba(0, 0, 0, 0.5)",
  },
  TestsCont:{
    minHeight:400,
    backgroundColor:"#f7f7f7",
    display:"flex",
    padding:"30px 10px",
    alignItems:'center',
    justifyContent:"space-evenly"
  },
  carouselCont:{
    width:"60%",
    maxWidth:500,
    backgroundColor:"#f7f7f7"
  },
  eachTestMon:{
   // width:60,
   // height:200,
    positon:"relative",
    backgroundColor:"#f7f7f7"
  },
  footerIconsCont:{
    display:"flex",
    width:"fit-content",
    margin:"auto"
  },
  linkPointer: {
    cursor: "pointer"
  },
  //Image Styling
  authorImg:{
    width:40,
    height:40,
    objectFit:"contain",
  },
  testBackImg:{
    width:100,
    height:100,
    objectFit:"contain",
    position:"absolute",
    top:'20%',
    zIndex:"-10",
    left:"3%"
  },
  footerIcon:{
    width:30,
    height:30,
    objectFit:"contain",
    marginRight:10,
    marginLeft:10
  },
  macbookImg:{
    width:"100%",
    height:"100%",
    objectFit:"contain",
  },
  addUserImg:{
    width:120,
    height:120,
    objectFit:"contain",
    marginRight:"auto"
  },
  group7Img:{
    width:100,
    height:100,
    objectFit:"contain",
    position:"absolute",
    top:'20%',
    zIndex:"-10",
    left:"3%"
  },
  bannerBackImg:{
    width:"100%",
    height:"100%",
    objectFit:"cover",
    position:"absolute",
    // left:0,
    zIndex:"-10",
    top:0,
    overflow: "visible"
  },
  AppStore:{
    width:100,
    height:30,
    objectFit:"contain"
  },
  QRCodeImg:{
    width:60,
    height:60,
    objectFit:"contain"
  },
  banner2Img:{
    width:"30%",
    objectFit:"contain"
  },
  bannerRightImg:{
    width:"100%",
    objectFit:"contain"
  },
  PlayStore:{
    width:100,
    marginTop:10,
    height:30,
    objectFit:"contain",
  },
  logoImg:{
    width:90,
    height:90,
    margin:"5px auto",
    objectFit:"contain",
    cursor: 'pointer'
  },
  navMiddleImg:{
    width:45,
    height:45,
    marginRight:"10px",
    objectFit:"contain",
  },
  navDownArrowImg:{
    width:20,
    height:20,
    marginLeft:"7px",
    float:"right",
    objectFit:"contain",
  },
  loginUser:{
    width:20,
    height:20,
    objectFit:"contain",
    marginRight:7
  },
  group12Img:{
    width:350,
    height:350,
    objectFit:"contain",
    position:"absolute",
    zIndex:"-10",
    bottom:'0%',
    right:"5%"
  },
  slideImg:{
    width:85,
    height:85,
    objectFit:"contain",
  },
  qrCodeImg: {
    height: 160,
    width: 160
  }
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(LandingPage));